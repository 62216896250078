import axios from "axios";
import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import Admin from "./pages/Admin";
import Swal from "sweetalert2";

function AdminPrivateRoute({ ...rest }) {
  const history = useHistory();

  const [Authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("/api/check-admin-access").then((res) => {
      if (res.status === 200) {
        setAuthenticated(true);
      }
      setLoading(false);
    });

    return () => {
      setAuthenticated(false);
    };
  }, []);

  axios.interceptors.response.use(
    undefined,
    function axiosRetryInterceptor(err) {
      if (err.response.status === 401) {
        Swal.fire({
          title: "Unauthorized",
          text: err.response.data.message || "Unauthorized",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
        history.push("/Login");
      }
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 403) {
        // acces denied
        Swal.fire({
          title: "Forbidden",
          text: error.response.data.message || "Forbidden",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
        history.push("403");
      } else if (error.response.status === 404) {
        // page not found
        Swal.fire({
          title: "404 Error",
          text: "Url/Page Not Found",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
        history.push("404");
      }
      return Promise.reject(error);
    }
  );

  if (loading) {
    return <h1 className="text-center">Loading...</h1>;
  }

  return (
    <Route
      {...rest}
      render={({ props, location }) =>
        Authenticated ? (
          <Admin {...props} />
        ) : (
          <Redirect to={{ pathname: "/Login", state: { from: location } }} />
        )
      }
    />
  );
}

export default AdminPrivateRoute;
