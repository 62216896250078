import Footer from "../components/Footer";
import fb from "../assets/Icons/fbs.svg";
import insta from "../assets/Icons/instagram.svg";
import twitter from "../assets/Icons/twitter-alt.svg";
import Wlogo from "../assets/logo/Blue Logo.svg";
import Navigation from "../components/Navigation";
import Blog from "../components/Blog";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import katrina from "../assets/Images/katrina.png";
import Form from "../components/Form";
export default function BlogPage() {
  const [blogData, setBlogData] = useState([]);

  const [showDiv, setShowDiv] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Esto lleva al usuario al principio de la página

    setShowDiv(!showDiv); // Cambia el estado o realiza la acción necesaria
  };
  const close = () => {
    setShowDiv(false); // Función para cerrar la div
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/posts/list");

        if (response.status === 200) {
          // Aquí puedes manejar la respuesta exitosa
          setBlogData(response.data.data);
        } else {
          // Manejar otros códigos de estado si es necesario
          console.error("Error en la solicitud:", response.status);
        }
      } catch (error) {
        // Manejar errores de red u otros errores
        console.error("Error en la solicitud:", error.message);
      }
    };

    fetchData(); // Llama a la función de solicitud al montar el componente
  }, []);

  const handleShow = (title, postId) => {
    const encodedTitle = encodeURIComponent(title);
    history.push(`/Blog/${encodedTitle}`, { postId });
  };

  return (
    <>
      {showDiv && <Form close={close} selectedOption={selectedOption} />}

      <div className="relative flex flex-col w-full h-96">
        <div className="absolute z-10 w-full h-full bg-black opacity-50"></div>

        <div className="absolute z-0 w-full h-full bg-bottom bg-no-repeat bg-cover bg-rie"></div>

        <div className="z-30 justify-end hidden w-full h-16 lg:flex">
          <div className="z-30 flex items-center w-40 gap-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=61553913384093"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb} className="w-5" alt="" />
            </a>
            <a
              href="https://www.instagram.com/techxus501c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} className="w-5" alt="" />
            </a>
            <a
              href="https://twitter.com/techxus501c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} className="w-5" alt="" />
            </a>
          </div>
        </div>

        <div className="relative z-20 flex items-center justify-between w-full h-20 p-4 pt-4 lg:border-t lg:border-b lg:border-white">
          <Link to="/">
            <img src={Wlogo} className="w-52" alt="logo" />
          </Link>
          <div className="w-auto h-20 ">
            <Navigation
              handle={handleLinkClick}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>

        <div className="flex items-center justify-center flex-grow w-full">
          <h1 className="z-10 text-2xl font-semibold text-white lg:text-5xl">
            OUR BLOGS
          </h1>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4">
        {blogData.map((blog, index) => (
          <Blog
            key={index}
            picture={`https://api.techxus.org/images/${blog.file}`}
            title={blog.title}
            text={
              blog.content.length > 150
                ? blog.content.slice(0, 150) + "..."
                : blog.content
            }
            date={moment(blog.created_at).format("YYYY-MM-DD")}
            views={blog.views}
            name={"Daniel Acquah"}
            likes={blog.likes}
            comments={blog.comments}
            profile={katrina}
            handleShow={() => handleShow(blog.title, blog.id)}
          />
        ))}
      </div>

      {/* <div className="flex items-center justify-center w-full mt-10">
        <a
          href="#"
          className="flex items-center justify-center h-12 text-white rounded-full w-44 bg-azul "
        >
          {" "}
          Load More
        </a>
      </div> */}

      <Footer handle={handleLinkClick} setSelectedOption={setSelectedOption} />
    </>
  );
}
