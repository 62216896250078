import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51Nw8dGFOVnzp2HvGEGYqyqIrP9zocKRRBd9VUwuyZgfusb2j4Q8MJ4Cz9Hj3mS9p6AHY2C9mbHIgJAzRfEApYetC00ufLQmok9"
);
export default function Donation() {
  return (
    <>
      <div className="">
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    </>
  );
}
