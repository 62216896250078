export default function Service({
  foto1,
  foto2,
  imageRef,
  title,
  text,
  key,
  handle,
  selectedOption,
  setSelectedOption,
}) {
  const divStyle = {
    backgroundImage: `url(${foto1})`,
  };

  const handleOptionClick = (option) => {
    console.log("Setting selected option:", option);
    setSelectedOption(option);
    handle(); // Assuming this function is responsible for closing the menu
  };

  return (
    <div className="relative flex items-center group">
      <div className="absolute z-10 w-full bg-black opacity-60 custom-height"></div>
      <div className="w-full overflow-hidden">
        <div
          className={`relative w-full h-full overflow-hidden transition-transform duration-300 transform bg-center bg-no-repeat bg-cover custom-height group-hover:scale-110`}
          style={{ backgroundImage: `url(${foto1})` }}
        >
          {/* <img
          src={foto1}
          ref={imageRef}
          className="z-0 w-full h-full transition-transform duration-300 transform scale-100 group-hover:scale-150"
          alt=""
        /> */}
        </div>
      </div>
      <div className="absolute z-10 flex flex-col w-full p-10 gap-y-4 group:hover">
        <div className="flex flex-col items-center justify-center w-full gap-y-4">
          <div>
            <img src={foto2} className="w-14" alt="" />
          </div>
          <div>
            <h2 className="text-2xl font-bold text-center text-white">
              {title}
            </h2>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-normal text-center text-blanco3 lg:font-light lg:text-base">
            {text}
          </h3>
        </div>

        <div className="flex items-center justify-center w-full">
          <button
            onClick={() => {
              handleOptionClick("Contact Us");
            }}
            className="flex items-center justify-center text-xs font-medium text-white bg-transparent border-2 border-white rounded-full group-hover:border-azul group-hover:bg-azul w-44 h-14 "
          >
            GET STARTED WITH US
          </button>
        </div>
      </div>
    </div>
  );
}
