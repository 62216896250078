import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const PaymentSuccess = () => {
  const history = useHistory();
  const [paymentInfo, setPaymentInfo] = useState(null);

  useEffect(() => {
    // Parse URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentId = urlParams.get("payment_intent");
    const clientSecret = urlParams.get("payment_intent_client_secret");
    const redirectStatus = urlParams.get("redirect_status");

    // Perform actions based on the information
    if (redirectStatus === "succeeded") {
      // Payment succeeded, you can show a success message or navigate to a success page
      console.log("Payment succeeded");

      // Store payment information if needed
      setPaymentInfo({ paymentIntentId, clientSecret });
    } else {
      // Handle other cases or show an error message
      console.error("Payment failed or unexpected redirect status");
      // Redirect to an error page or back to the home page
      history.push("/");
    }
  }, [history]);

  return (
    <div>
      <h1>Payment Success</h1>
      {paymentInfo && (
        <div>
          <p>Payment Intent ID: {paymentInfo.paymentIntentId}</p>
          <p>Client Secret: {paymentInfo.clientSecret}</p>
          {/* Add more details as needed */}
        </div>
      )}
      {/* You can customize the success message or UI here */}
    </div>
  );
};

export default PaymentSuccess;
