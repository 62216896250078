import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/Homepage";
import Blogs from "./pages/Blogs";
import Terms from "./pages/Terms";
import ShowBlog from "./pages/Blog";
import Login from "./pages/Login";
import axios from "axios";
import Admin from "./pages/Admin";
import CreatePost from "./components/CreatePost";
import EditPost from "./components/EditPost";
import AdminPrivateRoute from "../src/AdminPrivateRoute";
import PaymentSuccess from "./pages/PaymentCompleted";
import Email from "./pages/Email";
import Correo from "./pages/Emails";
import DonationsForm from "./pages/Donations";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CreditCard from "./components/Card";
import Forms from "./components/forms";
import CheckoutForm from "./components/CheckoutForm";
import PaymentReturnPage from "./components/Confirm";
axios.defaults.baseURL = "https://techxus.org/";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const stripePromise = loadStripe(
  "pk_live_51Nw8dGFOVnzp2HvGEGYqyqIrP9zocKRRBd9VUwuyZgfusb2j4Q8MJ4Cz9Hj3mS9p6AHY2C9mbHIgJAzRfEApYetC00ufLQmok9"
);
function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/Email">
            <Email />
          </Route>
          <Route exact path="/Emails">
            <Correo />
          </Route>
          <Route exact path="/Blogs">
            <Blogs />
          </Route>
          <Route exact path="/Forms">
            <Forms />
          </Route>
          <Route exact path="/Blog/:identifier">
            <ShowBlog />
          </Route>
          <Route exact path="/Terms&Conditions">
            <Terms />
          </Route>
          <Route exact path="/Login">
            <Login />
          </Route>

          <Route exact path="/payment-success">
            <PaymentSuccess />
          </Route>

          <AdminPrivateRoute exact path="/Admin" component={Admin} />
          <AdminPrivateRoute
            exact
            path="/Admin/Create/Post"
            component={CreatePost}
          />
          <AdminPrivateRoute
            exact
            path="/Admin/Edit/Post/:id"
            component={EditPost}
          />

          <Route exact path="/PaymentCompleted">
            <PaymentReturnPage />
          </Route>

          <Elements stripe={stripePromise}>
            <Route exact path="/Donations">
              <CheckoutForm />
            </Route>

            <Route exact path="/Credit">
              <CreditCard />
            </Route>
            <Route exact path="/Donation">
              <DonationsForm />
            </Route>
          </Elements>
        </Switch>
      </Router>
    </>
  );
}

export default App;
