import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "../App.css";
import { useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import axios from "axios";
export default function Payment() {
  const stripe = useStripe();
  const elements = useElements();
  const paymentElementOptions = {
    layout: "tabs",
  };
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      Swal.fire(
        "Error",
        "Payment service is not available. Please try again later.",
        "error"
      );
      return;
    }

    setIsLoading(true);

    try {
      // Asegúrate de obtener el PaymentElement montado
      const cardElement = elements.getElement(PaymentElement);

      // Verifica que el cardElement no sea null
      if (!cardElement) {
        console.error(
          "It seems like the PaymentElement was not properly mounted."
        );
        Swal.fire(
          "Error",
          "There was an issue with the payment form. Please try again.",
          "error"
        );
        setIsLoading(false);
        return;
      }

      const result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          receipt_email: email,
          return_url: "https://techxus.org/PaymentCompleted",
          // Agrega aquí otros parámetros según sea necesario
        },
      });

      if (result.error) {
        console.error("Payment confirmation error:", result.error.message);
        Swal.fire("Error", result.error.message, "error");
        setIsLoading(false);
        return;
      }

      if (
        result.paymentIntent &&
        result.paymentIntent.status === "requires_action"
      ) {
        console.log(result);
        console.log(result.paymentIntent.next_action);
        let url =
          result.paymentIntent.next_action.verify_with_microdeposits
            .hosted_verification_url;
        console.log(url);

        history.push("/");

        Swal.fire({
          title: "Processing...",
          text: "Microdeposits have been sent to your bank account. It may take up to 2 days for the transaction to complete. You will receive an email with further instructions. Thank you for your time.",
          icon: "success",
        });
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === "processing"
      ) {
        console.log(result);

        try {
          const response = await axios.post("/api/send-donation-email", {
            email: email, // Email del donante
            // Otros detalles relevantes que quieras incluir en el correo electrónico
          });

          if (response.status === 200) {
            // La solicitud fue exitosa
            Swal.fire({
              title: "Processing...",
              text: "Your bank transfer is being processed. Thank you for your donation.",
              icon: "success",
            });
            history.push("/");
          } else {
            console.error("Unexpected server response:", response);
            Swal.fire({
              title: "Thank You!",
              text: "Your donation has been successfully processed. A confirmation email will be sent to you shortly.",
              icon: "success",
            });
            history.push("/");
            // Manejar el caso en el que el servidor respondió con un código de estado inesperado
          }
        } catch (emailError) {
          console.error("Error sending donation email:", emailError);
          // Puedes decidir cómo manejar los errores al enviar el correo electrónico
        }

        // No redirigir inmediatamente, esperar a que el usuario sea redirigido a la URL de retorno
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === "succeeded"
      ) {
        console.log(result);

        try {
          const response = await axios.post("/api/send-donation-email", {
            email: email, // Email del donante
            // Otros detalles relevantes que quieras incluir en el correo electrónico
          });

          if (response.status === 200) {
            // La solicitud fue exitosa
            Swal.fire({
              title: "Thank You!",
              text: "Your donation has been successfully processed. A confirmation email will be sent to you shortly.",
              icon: "success",
            });
            history.push("/");
          } else {
            console.error("Unexpected server response:", response);
            Swal.fire({
              title: "Thank You!",
              text: "Your donation has been successfully processed. A confirmation email will be sent to you shortly.",
              icon: "success",
            });
            history.push("/");
            // Manejar el caso en el que el servidor respondió con un código de estado inesperado
          }
        } catch (emailError) {
          console.error("Error sending donation email:", emailError);
          // Puedes decidir cómo manejar los errores al enviar el correo electrónico
        }
      }
    } catch (error) {
      console.error("Error during the payment process:", error);
      Swal.fire(
        "Error",
        "An error occurred during the payment process. Please try again.",
        "error"
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="w-4/5 p-6 mx-auto my-0">
      <input
        id="email"
        type="text"
        value={email}
        required
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
      />

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        className="Sbutton"
        disabled={isLoading || !stripe || !elements}
        id="submit"
        onClick={handleSubmit}
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </div>
  );
}
