import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import receipt from "../assets/Icons/receipt.svg";
import receiptwhite from "../assets/Icons/receipt-white.svg";
import calendar from "../assets/Icons/calendar.svg";
import calendargrey from "../assets/Icons/calendar-grey.svg";
import Swal from "sweetalert2";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import Payment from "./forms";
import Subscription from "./Subscription";

const stripePromise = loadStripe(
  "pk_live_51Nw8dGFOVnzp2HvGEGYqyqIrP9zocKRRBd9VUwuyZgfusb2j4Q8MJ4Cz9Hj3mS9p6AHY2C9mbHIgJAzRfEApYetC00ufLQmok9"
);

export default function CheckoutForm() {
  const [clientSecret, setClientSecret] = useState(null); // Nuevo estado para el clientSecret

  const [isMonthly, setIsMonthly] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    amount: "", // Agrega esto
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    country: "",
    customAmount: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newFormData = { ...formData, [name]: value };

    // Si el cambio es en customAmount, actualiza también amount
    if (name === "customAmount") {
      newFormData.amount = value;
    }

    setFormData(newFormData);
  };

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();

  //     if (!stripe || !elements) {
  //       console.error("Stripe.js has not loaded yet.");
  //       Swal.fire(
  //         "Error",
  //         "Payment service is not available. Please try again later.",
  //         "error"
  //       );
  //       return;
  //     }

  //     setIsLoading(true);

  //     try {
  //       // Asegúrate de obtener el PaymentElement montado
  //       const cardElement = elements.getElement(PaymentElement);

  //       // Verifica que el cardElement no sea null
  //       if (!cardElement) {
  //         console.error(
  //           "It seems like the PaymentElement was not properly mounted."
  //         );
  //         Swal.fire(
  //           "Error",
  //           "There was an issue with the payment form. Please try again.",
  //           "error"
  //         );
  //         setIsLoading(false);
  //         return;
  //       }

  //       const result = await stripe.confirmPayment({
  //         elements,
  //         redirect: "if_required",
  //         confirmParams: {
  //           receipt_email: email,
  //           // Agrega aquí otros parámetros según sea necesario
  //         },
  //       });

  //       if (result.error) {
  //         console.error("Payment confirmation error:", result.error.message);
  //         Swal.fire("Error", result.error.message, "error");
  //         setIsLoading(false);
  //         return;
  //       }

  //       if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
  //         Swal.fire({
  //           title: "Thank You!",
  //           text: "Your payment has been successfully processed.",
  //           icon: "success",
  //         });
  //         history.push("/payment-success");
  //       }
  //     } catch (error) {
  //       console.error("Error during the payment process:", error);
  //       Swal.fire(
  //         "Error",
  //         "An error occurred during the payment process. Please try again.",
  //         "error"
  //       );
  //       setIsLoading(false);
  //     }
  //   };

  const fetchClientSecret = async (selectedAmount) => {
    try {
      const response = await axios.post("/api/create", {
        amount: selectedAmount,
      });
      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.error("Error fetching clientSecret:", error);
      // Manejar errores aquí, por ejemplo, mostrar un mensaje de error al usuario
    }
  };

  const handleCreateSubscription = async () => {
    if (isMonthly) {
      try {
        // Crear cliente en el backend
        const customerResponse = await axios.post("/api/create-customer", {
          email: formData.email,
          name: `${formData.firstName} ${formData.lastName}`,
          address: {
            line1: formData.address,
            city: formData.city,
            state: formData.state,
            postal_code: formData.zip,
            country: formData.country,
          },
          phone: formData.phone,
        });

        const customerId = customerResponse.data.customerId;
        const customer = customerResponse;
        console.log(customer);
        // Ahora crear la suscripción con el ID del cliente y el monto
        const subscriptionResponse = await axios.post(
          "/api/create-subscription",
          {
            customerId,
            amount: parseFloat(formData.amount), // Asegúrate de que este sea el ID de precio correcto o el monto a cobrar
          }
        );

        if (subscriptionResponse.data.clientSecret) {
          setClientSecret(subscriptionResponse.data.clientSecret);
          // Puede que necesites realizar una confirmación de pago aquí
        }
      } catch (error) {
        console.error("Error creating subscription:", error);
        Swal.fire(
          "Error",
          "An error occurred while creating the subscription.",
          "error"
        );
      }
    }
  };

  const [selected, setSelected] = useState(null);

  // Update this function to set the amount based on the selection
  //   const handleSelect = (index) => {
  //     if (index === divs.length) {
  //       setSelected(index);
  //       // Si es 'Custom Amount', usa el valor de 'customAmount' del estado del formulario
  //       setFormData({ ...formData, amount: formData.customAmount });
  //       // Llama a fetchClientSecret para obtener el clientSecret cuando se selecciona un monto personalizado
  //       fetchClientSecret(formData.customAmount);
  //     } else if (index >= 0 && index < divs.length) {
  //       setSelected(index);
  //       const donationAmount = divs[index].text.replace("$", "");
  //       setFormData({ ...formData, customAmount: "", amount: donationAmount });
  //       // Llama a fetchClientSecret para obtener el clientSecret cuando se selecciona un monto preestablecido
  //       fetchClientSecret(donationAmount);
  //     } else {
  //       console.error("Índice seleccionado inválido:", index);
  //     }
  //   };

  const handleSelect = (index) => {
    setSelected(index);

    if (index >= 0 && index < divs.length) {
      // Si se selecciona un monto predefinido
      const amount = divs[index].text.replace("$", "");
      setFormData({ ...formData, amount: amount });
    } else {
      // Si se selecciona Custom Amount, resetea el monto
      setFormData({ ...formData, amount: "" });
    }
  };

  const handleContinueToPayment = () => {
    // Verificar si se ha seleccionado o ingresado una cantidad válida
    if (
      !formData.amount ||
      isNaN(parseFloat(formData.amount)) ||
      parseFloat(formData.amount) <= 0
    ) {
      Swal.fire("Error", "Please select or enter a valid amount.", "error");
      return;
    }

    // Llamar a fetchClientSecret con la cantidad seleccionada
    fetchClientSecret(formData.amount);
  };

  // ... [rest of the code] ...

  const divs = [
    {
      text: "$100",
      background: "bg-azul2 text-azul border-azul",
    },
    {
      text: "$500",
      background: "bg-azul2 text-azul border-azul",
    },
    {
      text: "$1000",
      background: "bg-azul2 text-azul border-azul",
    },
  ];

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handlePaymentProcess = () => {
    // Verifica si es una suscripción mensual o un pago único
    if (isMonthly) {
      handleCreateSubscription(); // Maneja la creación de la suscripción
    } else {
      handleContinueToPayment(); // Procede al proceso de pago
    }
  };

  return (
    <div>
      <form id="payment-form" className="Sform lg:w-full">
        <div className="flex flex-col w-full mt-10 gap-y-4 lg:w-4/5 lg:mx-auto lg:my-0 lg:mt-20">
          <div>
            <h2 className="text-xl font-bold text-center lg:text-4xl lg:font-semibold">
              Donate to Empower Future Technologists
            </h2>
          </div>
          <div>
            <h3 className="text-sm font-medium text-center lg:text-xl">
              Your donation today, their brighter tomorrow.
            </h3>
          </div>
          {/* Tu formulario existente */}{" "}
          <label className="relative inline-flex items-center h-16 p-2 mx-auto my-0 bg-gray-200 rounded-full cursor-pointer w-60">
            <input
              type="checkbox"
              className="hidden"
              checked={isMonthly}
              onChange={handleToggle}
            />
            <span
              className={`w-28 h-12 absolute bg-blue-500 z-0 rounded-full transition-transform duration-300 ease-in-out transform ${
                isMonthly ? "translate-x-28" : ""
              }`}
            ></span>
            <div className="flex items-center pl-2">
              <img
                src={isMonthly ? receipt : receiptwhite}
                className="z-10"
                alt=""
              />
              <span
                className={`pl-2 text-xs font-bold z-10  ${
                  isMonthly ? "text-gray-600" : "text-white"
                }`}
              >
                One Time
              </span>
            </div>
            <div className="flex items-center ml-8 gap-x-2">
              <img
                src={isMonthly ? calendar : calendargrey}
                className="z-10"
                alt=""
              />
              <span
                className={`pr-2 text-xs font-bold z-10  ${
                  isMonthly ? "text-white" : "text-gray-600"
                }`}
              >
                Monthly
              </span>
            </div>
          </label>
          <div className="grid w-full grid-cols-2 mt-5 justify-items-center gap-y-4 lg:flex lg:justify-evenly">
            {divs.map((div, index) => (
              <div
                key={index}
                className={`w-40 lg:w-60 h-12 border rounded-lg border-gris11 group focus:border-azul flex items-center justify-center ${
                  index === selected
                    ? `${div.background} border-azul border-none ring-2 ring-azul`
                    : "group-hover:bg-playazul border-azul text-gris12 group-hover:text-white"
                }`}
                onClick={() => handleSelect(index)}
              >
                <h4>{div.text}</h4>
              </div>
            ))}

            <div
              className={`w-40 lg:w-60 h-12 border rounded-lg border-gris11 group focus:border-none flex items-center justify-center ${
                selected === divs.length
                  ? `${divs[0].background} border-azul bg-azul2  ring-2 ring-azul`
                  : "group-hover:bg-playazul bg-azul2 border-azul text-gris12 group-hover:text-white"
              }`}
              onClick={() => handleSelect(divs.length)}
            >
              <input
                type="text"
                placeholder="Custom"
                className="w-full h-full pl-12 border-none rounded-lg outline-none lg:pl-20"
                name="customAmount"
                value={formData.customAmount}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="mt-5">
            <h2 className="font-bold lg:text-xl">Your Details</h2>
          </div>
          <div className="flex flex-col gap-y-4 lg:hidden">
            <input
              type="text"
              placeholder="First Name"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              value={formData.firstName}
              name="firstName"
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="Last Name"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            {/* <input
              type="email"
              placeholder="Your Email"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            /> */}
            <input
              type="text"
              placeholder="Your Phone"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
            {/* <input
              type="text"
              placeholder="Country"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
            /> */}
            <input
              type="text"
              placeholder="Street Adress"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="City"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="State"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="Zip"
              className="w-full h-12 pl-4 border rounded border-gris6 placeholder:text-gris6"
              name="zip"
              value={formData.zip}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex-col hidden lg:flex gap-y-4">
            <div className="flex gap-x-4">
              <input
                type="text"
                placeholder="First Name"
                className="w-full h-16 pl-4 border rounded border-gris6 placeholder:text-gris6"
                value={formData.firstName}
                onChange={handleInputChange}
                name="firstName"
              />
              <input
                type="text"
                placeholder="Last Name"
                className="w-full h-16 pl-4 border rounded border-gris6 placeholder:text-gris6"
                value={formData.lastName}
                onChange={handleInputChange}
                name="lastName"
              />
            </div>

            {/* <input
              type="email"
              placeholder="Your Email"
              className="w-full h-16 pl-4 border rounded border-gris6 placeholder:text-gris6"
              value={formData.email}
              onChange={handleInputChange}
              name="email"
            /> */}
            <input
              type="string"
              placeholder="Your Phone"
              className="w-full h-16 pl-4 border rounded border-gris6 placeholder:text-gris6"
              value={formData.phone}
              onChange={handleInputChange}
              name="phone"
            />
            <input
              type="text"
              placeholder="Street Adress"
              className="w-full h-16 pl-4 border rounded border-gris6 placeholder:text-gris6"
              value={formData.address}
              onChange={handleInputChange}
              name="address"
            />
            <div className="flex gap-x-4">
              <input
                type="text"
                placeholder="City"
                className="w-full h-16 pl-4 border rounded border-gris6 placeholder:text-gris6"
                value={formData.city}
                onChange={handleInputChange}
                name="city"
              />
              <input
                type="text"
                placeholder="State"
                className="w-full h-16 pl-4 border rounded border-gris6 placeholder:text-gris6"
                value={formData.state}
                onChange={handleInputChange}
                name="state"
              />
              <input
                type="text"
                placeholder="Zip"
                className="w-full h-16 pl-4 border rounded border-gris6 placeholder:text-gris6"
                value={formData.zip}
                onChange={handleInputChange}
                name="zip"
              />
            </div>
          </div>
        </div>
      </form>
      {clientSecret !== null ? (
        <Elements options={options} stripe={stripePromise}>
          {isMonthly ? <Subscription /> : <Payment />}
        </Elements>
      ) : (
        <div className="flex items-center justify-center w-4/5 mx-auto my-0 lg:pl-8 lg:pr-8">
          <button
            className="flex items-center justify-center w-full p-6 mx-auto my-0 text-white rounded h-14 bg-azul"
            onClick={handlePaymentProcess}
          >
            Continue to Payment
          </button>
        </div>
      )}
    </div>
  );
}
