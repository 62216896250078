import fb from "../assets/Email/facebook (1).svg";
import logo from "../assets/logo/Blue Logo.svg";
import book from "../assets/Email/books.png";
import facebook from "../assets/Email/Group 39313.svg";
import twitter from "../assets/Email/Group 39312.svg";
import instagram from "../assets/Email/Group 39314.svg";
import linkedin from "../assets/Email/Group 39315.svg";
import youtube from "../assets/Email/Group 39316.svg";
export default function Email() {
  return (
    <>
      <div className="flex flex-col w-full min-h-screen">
        <div className="flex justify-between w-full h-20 px-5 bg-white lg:px-10">
          <img src={logo} className="w-40 lg:w-auto" alt="" />
          <div className="flex w-auto gap-x-4">
            <img src={fb} alt="" />
            <img src={fb} alt="" />
            <img src={fb} alt="" />
          </div>
        </div>
        <div className="flex flex-col items-center flex-1 px-8 pb-10 bg-azul lg:px-14 gap-y-4">
          <img src={book} className="w-40 lg:w-52" alt="" />
          <h1 className="text-xl font-semibold text-center text-white lg:text-2xl">
            Thank you for your generous <br /> donation to Techxus!
          </h1>
          <div className="mx-auto my-0 lg:w-4/6">
            <h2 className="text-sm font-normal text-center text-white lg:text-lg">
              Your support fuels our mission to empower minorities through free
              technology education. As a 501(c)(3) nonprofit, we're committed to
              breaking down barriers and providing 100% FREE resources. Your
              contribution directly impacts innovation and self-reliance in the
              digital world. Together, we're creating a future where everyone
              thrives in technology. Thank you for being a crucial part of the
              Techxus journey!
            </h2>
          </div>

          <button className="h-10 text-sm bg-white rounded-full w-44 lg:h-12 lg:w-60 text-azul ">
            Visit our website{" "}
          </button>
        </div>
        <div className="flex flex-col justify-between w-full h-40 lg:h-52 px-5 pt-5 lg:px-20 mb-2.5">
          <div className="flex justify-center w-3/5 mx-auto my-0 gap-x-4">
            <img src={facebook} className="w-8 lg:w-12" alt="" />
            <img src={twitter} className="w-8 lg:w-12" alt="" />
            <img src={instagram} className="w-8 lg:w-12" alt="" />
            <img src={linkedin} className="w-8 lg:w-12" alt="" />
            <img src={youtube} className="w-8 lg:w-12" alt="" />
          </div>
          <div className="mx-auto my-0 text-xs text-center lg:w-3/6 lg:text-base text-gris7 ">
            <p>
              Received this email without having contacted us? Please{" "}
              <span className="text-azul">report it here</span> . If you no
              longer wish to receive emails from us in the future, you can{" "}
              <span className="text-azul">unsubscribe from our newsletter</span>{" "}
              .
            </p>
          </div>

          <div className="w-full text-xs font-bold text-center">
            <p>Copyright 2024 © Techxus</p>
          </div>
        </div>
      </div>
    </>
  );
}
