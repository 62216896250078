import React, { useRef, useState, useEffect } from "react";
import video from "../assets/Video/aboutus-video.mp4";
import picture from "../assets/Images/about-1.jpg";

function VideoPlayer() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Nuevo estado para el popup

  const handlePlayPauseToggle = () => {
    if (videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        console.error("Error trying to play the video:", error);
      });
    } else {
      videoRef.current.pause();
    }
  };

  const pauseVideo = () => {
    if (!videoRef.current.paused) {
      videoRef.current.pause();
    }
  };

  // Función para manejar el cierre del popup y pausar el video
  const closePopup = () => {
    setIsPopupVisible(false);
    pauseVideo();
  };

  // Efecto para sincronizar el estado con el estado real de reproducción del vídeo
  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    videoElement.addEventListener("play", handlePlay);
    videoElement.addEventListener("pause", handlePause);

    // Limpiar los event listeners al desmontar
    return () => {
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
    };
  }, []);

  // CSS dinámico para el reproductor cuando se muestra como popup
  const popupStyle = {
    width: isPopupVisible ? "80%" : "auto",
    height: isPopupVisible ? "80%" : "auto",
    position: isPopupVisible ? "fixed" : "relative",
    top: isPopupVisible ? "50%" : "auto",
    left: isPopupVisible ? "50%" : "auto",
    transform: isPopupVisible ? "translate(-50%, -50%)" : "none",
    zIndex: isPopupVisible ? 1000 : "auto",
    backgroundColor: isPopupVisible ? "rgba(0, 0, 0, 0.75)" : "transparent",
  };

  // CSS para la capa de fondo que cubre toda la pantalla
  const backgroundLayerStyle = {
    display: isPopupVisible ? "block" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: isPopupVisible ? 999 : "auto",
  };

  return (
    <div className="relative flex w-full">
      {/* Capa de fondo */}
      <div style={backgroundLayerStyle} onClick={closePopup}></div>

      <video
        ref={videoRef}
        poster={picture}
        controls={true}
        className="video rounded-xl"
        style={popupStyle}
      >
        <source src={video} type="video/mp4" />
        Tu navegador no admite la reproducción de video.
      </video>
      {!isPlaying && (
        <button
          onClick={() => {
            setIsPopupVisible(true);
            handlePlayPauseToggle();
          }}
          className={`absolute inset-0 z-20 w-full h-full cursor-pointer ${
            isPopupVisible ? "hidden" : ""
          }`}
        >
          {/* Botón personalizado para reproducir/pausar */}
        </button>
      )}
    </div>
  );
}

export default VideoPlayer;
