import axios from "axios";
import { Table } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
export default function Admin() {
  const history = useHistory();

  const handleLogout = async () => {
    // const token = localStorage.getItem("auth_token");

    try {
      const response = await axios.post(`/api/logout`);

      if (response.status === 200) {
        // Remove auth_token and auth_name from local storage
        localStorage.removeItem("auth_email");
        localStorage.removeItem("auth_token");
        localStorage.removeItem("type");

        // Redirect to the login page
        history.push("/Login");
        Swal.fire({
          title: "¡ Good Job !",
          text: "¡ You Logged Out! ",
          icon: "success",
        });
      } else {
        console.error("Error: Unable to logout");
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Llamada a la API usando Axios
        const response = await axios.get("api/posts/list");

        // Verifica si el código de estado es 200 (OK)
        if (response.status === 200) {
          // Actualiza el estado con los datos recibidos
          setArticles(response.data.data);
        } else {
          // Manejo de otros códigos de estado si es necesario
          console.error("Error al obtener los datos de la API");
        }
      } catch (error) {
        // Manejo de errores en la llamada a la API
        console.error("Error al realizar la llamada a la API", error);
      }
    };

    // Llamada a la función para obtener los datos al montar el componente
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      // Mostrar cuadro de diálogo de confirmación con SweetAlert
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // Sólo proceder con la eliminación si el usuario confirma
        const response = await axios.delete(`/api/posts/${id}/delete`);

        if (response.status === 200) {
          // Actualizar el estado después de la eliminación
          setArticles(articles.filter((article) => article.id !== id));

          Swal.fire("Deleted!", "Your article has been deleted.", "success");
        } else {
          console.error("Error al eliminar el artículo");
        }
      }
    } catch (error) {
      console.error(
        "Error al realizar la llamada para eliminar el artículo",
        error
      );
    }
  };

  const handleEdit = (id) => {
    // Redirigir al usuario a la página de edición con el ID en la URL
    history.push(`/Admin/Edit/Post/${id}`);
  };

  return (
    <div className="w-10/12 min-h-screen mx-auto my-0">
      <div className="flex justify-between pt-5 pb-5">
        <Link
          to="/Admin/Create/Post"
          className="p-2 text-white bg-blue-700 rounded"
        >
          Create Post
        </Link>
        <button
          onClick={handleLogout}
          className="p-2 text-white bg-red-500 rounded"
        >
          Log Out
        </button>
      </div>
      <Table>
        <Table.Head>
          <Table.HeadCell>ID</Table.HeadCell>
          <Table.HeadCell>Title</Table.HeadCell>
          <Table.HeadCell>Date</Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Delete</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {articles.map((item, index) => (
            <Table.Row
              key={index}
              className="bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              <Table.Cell className="font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {index + 1}
              </Table.Cell>
              <Table.Cell>{item.title}</Table.Cell>{" "}
              <Table.Cell>
                {moment(item.created_at).format("DD/MM/YYYY")}
              </Table.Cell>
              <Table.Cell>
                <button
                  onClick={() => handleEdit(item.id)}
                  className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                >
                  Edit
                </button>
              </Table.Cell>
              <Table.Cell>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                >
                  Delete
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
