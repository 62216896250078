import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { RingLoader } from "react-spinners";

const PaymentReturnPage = () => {
  const history = useHistory();
  // Asegúrate de obtener `email` y `formData` de alguna fuente (estado global, localStorage, etc.)

  useEffect(() => {
    const processPayment = async () => {
      console.log("Processing payment...");
      const urlParams = new URLSearchParams(window.location.search);
      const paymentIntentId = urlParams.get("payment_intent");

      if (paymentIntentId) {
        try {
          const response = await axios.post("/api/subscription-final", {
            paymentIntentId: paymentIntentId,
          });

          console.log("Response from server:", response);
          console.log("Response from server Status:", response.status);

          if (response.data.status === "success") {
            Swal.fire("Success", response.data.message, "success");
            history.push("/");
          } else if (response.data.status === "processing") {
            Swal.fire("Processing", response.data.message, "info");
            history.push("/");
          } else if (
            response.data.paymentIntent &&
            response.data.paymentIntent.status === "requires_action"
          ) {
            // let url =
            //   response.data.paymentIntent.next_action.verify_with_microdeposits
            //     .hosted_verification_url;

            Swal.fire({
              title: "Processing...",
              text: "Microdeposits have been sent to your bank account. It may take up to 2 days for the transaction to complete. You will receive an email with further instructions. Thank you for your time.",
              icon: "success",
            });

            // Puedes decidir mantener al usuario en la página actual o redirigirlo a otra parte
          } else {
            console.error(
              "Error during subscription process:",
              response.data.message
            );
            Swal.fire("Error", response.data.message, "error");
          }
        } catch (error) {
          console.error("Error during subscription process:", error);
          Swal.fire("Error", error.toString(), "error");
        }
      } else {
        console.error("No paymentIntentId found in URL after return.");
        Swal.fire(
          "Error",
          "No payment intent found. Please try again.",
          "error"
        );
      }
    };

    processPayment();
  }, [history]);

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <RingLoader
        color={"#006FFF"}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default PaymentReturnPage;
