import React, { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink, useLocation, Link } from "react-router-dom";
import share from "../assets/Icons/sh.svg";
import arrow from "../assets/Icons/arrow.svg";
import whitearrow from "../assets/Icons/arrow-down-3101.svg";
import whiteshare from "../assets/Icons/share (2).jpg";
import { HashLink, NavHashLink } from "react-router-hash-link";

export default function Navigation({
  handle,
  selectedOption,
  setSelectedOption,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGetInvolvedOpen, setIsGetInvolvedOpen] = useState(false);

  // ... Resto del código ...

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Esta función maneja los cambios de estado del menú
  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false); // Esto cerrará el menú
  };

  const toggleGetInvolved = () => {
    setIsGetInvolvedOpen(!isGetInvolvedOpen);
  };

  const location = useLocation();
  const handleOptionClick = (option) => {
    console.log("Setting selected option:", option);
    setSelectedOption(option);
    handle(); // Assuming this function is responsible for closing the menu
  };

  const [navTextColor, setNavTextColor] = useState("white");
  const [arrowImage, setArrowImage] = useState(arrow);
  const [shareImage, setShareImage] = useState(share);

  useEffect(() => {
    if (
      location.pathname === "/Terms&Conditions" ||
      location.pathname === "/Blog" ||
      location.pathname.startsWith("/Blog/")
    ) {
      setNavTextColor("black");
      setArrowImage(whitearrow);
      setShareImage(whiteshare);
    } else {
      setNavTextColor("white");
      setArrowImage(arrow);
      setShareImage(share);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className="hidden h-full text-white lg:flex "
        style={{ color: navTextColor, borderColor: navTextColor }}
      >
        <div
          className="flex items-center justify-center w-auto h-full pl-6 pr-6 border border-white"
          style={{ borderColor: navTextColor }}
        >
          <HashLink smooth to="/#about" style={{ color: navTextColor }}>
            About us
          </HashLink>
        </div>

        <div
          className="flex items-center justify-center w-auto h-full pl-6 pr-6 border border-l-0 border-white"
          style={{ borderColor: navTextColor }}
        >
          <NavHashLink to="/#programs">Programs</NavHashLink>
        </div>

        <div
          className="flex items-center justify-center w-auto h-full pl-6 pr-6 border border-white"
          style={{ borderColor: navTextColor }}
        >
          <HashLink to="/#services" style={{ color: navTextColor }}>
            Services
          </HashLink>
        </div>

        <div
          className="relative w-auto h-full transition-transform duration-700 border border-l-0 border-white group "
          style={{ borderColor: navTextColor }}
        >
          <div className="flex items-center justify-center w-full h-full pl-6 pr-6 gap-x-2">
            <Link to="#">Get Involved</Link>
            <img src={arrowImage} className="w-4" alt="" />
          </div>

          <div
            className="absolute z-20 flex-col invisible w-full pt-4 font-medium text-black bg-transparent h-72 hover:flex group-hover:visible"
            style={{ borderColor: navTextColor }}
          >
            <div
              className="flex flex-col w-full h-full p-4 bg-white border border-white gap-y-3"
              style={{ borderColor: navTextColor }}
            >
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Report Issue");
                  handle();
                }}
              >
                Report Issue
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Feedback");
                  handle();
                }}
              >
                Feedback
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Suggestions");
                  handle();
                }}
              >
                Suggestions
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Volunteer");
                  handle();
                }}
              >
                Volunteer
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Partner");
                  handle();
                }}
              >
                Partner
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Subscribe");
                  handle();
                }}
              >
                Subscribe
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Contact Us");
                  handle();
                }}
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>

        <div
          className="flex items-center justify-center w-auto h-full pl-6 pr-6 border border-l-0 border-white"
          style={{ borderColor: navTextColor }}
        >
          <HashLink to="/#blogs" style={{ color: navTextColor }}>
            Blogs
          </HashLink>
        </div>

        <div
          className="flex items-center justify-center w-auto h-full pl-6 pr-6 border border-l-0 border-white"
          style={{ borderColor: navTextColor }}
        >
          <Link to="/Donation">Donate</Link>
        </div>
        {/* 
        <div
          className="flex items-center justify-center w-auto h-full pl-6 pr-6 gap-x-4"
          style={{ borderColor: navTextColor }}
        >
          <img src={shareImage} className="w-4" alt="" />
          <Link to="#">Share</Link>
        </div> */}
      </div>

      <div className="relative flex w-20 h-full lg:hidden ">
        <Menu right isOpen={isMenuOpen} onStateChange={handleStateChange}>
          <NavLink to="/" onClick={closeMenu} exact className="text-white">
            Home
          </NavLink>
          <HashLink
            to="/#about"
            onClick={closeMenu}
            exact
            className="text-white"
          >
            About Us
          </HashLink>
          <HashLink
            to="/#programs"
            onClick={closeMenu}
            exact
            className="text-white"
          >
            Programs
          </HashLink>
          <HashLink
            to="/#services"
            onClick={closeMenu}
            exact
            className="text-white"
          >
            Services
          </HashLink>
          <div
            onClick={toggleGetInvolved}
            className="flex flex-col text-white cursor-pointer"
          >
            Get Involved
          </div>
          {isGetInvolvedOpen && (
            <div className="flex flex-col items-center text-sm text-black custom-list gap-y-5">
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Report Issue");
                  handle();
                  closeMenu();
                }}
              >
                Report Issue
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Feedback");
                  handle();
                  closeMenu();
                }}
              >
                Feedback
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Suggestions");
                  handle();
                  closeMenu();
                }}
              >
                Suggestions
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Volunteer");
                  handle();
                  closeMenu();
                }}
              >
                Volunteer
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Partner");
                  handle();
                  closeMenu();
                }}
              >
                Partner
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Subscribe");
                  handle();
                  closeMenu();
                }}
              >
                Subscribe
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleOptionClick("Contact Us");
                  handle();
                  closeMenu();
                }}
              >
                Contact us
              </Link>
            </div>
          )}
          <NavLink to="/Blogs" exact className="text-white">
            Blogs
          </NavLink>
          <NavLink to="/Donation" exact className="text-white">
            Donate
          </NavLink>
        </Menu>
      </div>
    </>
  );
}
