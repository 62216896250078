import fb from "../assets/Icons/facebook-blue.svg";
import fbgris from "../assets/Icons/f.svg";
import insta from "../assets/Icons/instagram-blue.svg";
import instagris from "../assets/Icons/insta.svg";
import twitter from "../assets/Icons/blue-x.svg";
import Blogo from "../assets/logo/Blue Logo.svg";
import Navigation from "../components/Navigation";
import facebook from "../assets/Icons/fb.svg";
import instagram from "../assets/Icons/insta-c.svg";
import twit from "../assets/Icons/icX.svg";
import profile from "../assets/Images/Ellipse 118.png";
import comments from "../assets/Icons/comments.svg";
import Comments from "../components/Comments";
import Related from "../components/Related";
import Footer from "../components/Footer";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import Form from "../components/Form";

export default function ShowBlog() {
  const location = useLocation();
  const { identifier } = useParams();
  console.log(identifier);
  const postId = location.state ? location.state.postId : null;
  const [post, setPost] = useState(null);
  const [adminPost, setAdminPost] = useState(null);
  const [randomPost, setRandomPost] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    comment: "",
  });

  const [visibleComments, setVisibleComments] = useState(5);

  const handleLoadMore = () => {
    // Incrementar el número de comentarios visibles
    setVisibleComments(visibleComments + 5);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [showDiv, setShowDiv] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Esto lleva al usuario al principio de la página

    setShowDiv(!showDiv); // Cambia el estado o realiza la acción necesaria
  };
  const close = () => {
    setShowDiv(false); // Función para cerrar la div
  };

  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.get(`api/posts/${identifier}`);

        // Verificar si la respuesta tiene el código de estado 200
        if (response.status === 200) {
          setPost(response.data.data);
        } else {
          console.error("Error fetching post details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    postData();
  }, [identifier]);

  useEffect(() => {
    const postComments = async () => {
      try {
        const response = await axios.get(`api/admin/posts/${postId}`);

        // Verificar si la respuesta tiene el código de estado 200
        if (response.status === 200) {
          setAdminPost(response.data.data);
        } else {
          console.error("Error fetching post details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    postComments();
  }, [postId]);

  useEffect(() => {
    const checkAdminAccess = async () => {
      try {
        const response = await axios.get("api/check-admin-access");

        if (response.status === 200) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error checking admin access:", error);
      }
    };

    checkAdminAccess();
  }, []);

  useEffect(() => {
    const randomPost = async () => {
      try {
        const response = await axios.get(`api/random/posts`);

        const currentPostId = postId;

        // Verificar si la respuesta tiene el código de estado 200
        if (response.status === 200) {
          const filteredPosts = response.data.data.filter(
            (post) => post.id !== currentPostId
          );

          // Establece el estado con el post aleatorio filtrado
          setRandomPost(filteredPosts);
        } else {
          console.error("Error fetching post details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    randomPost();
  }, [postId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `api/comments/create/${postId}`,
        formData
      );

      if (response.status === 201) {
        // Mostrar Swal si el comentario se ha enviado con éxito
        Swal.fire({
          icon: "success",
          title: "Comment Submitted!",
          text: "Your comment has been submitted and is pending verification.",
          showConfirmButton: false,
          timer: 2000, // Oculta automáticamente después de 2 segundos
        });

        // Actualizar el estado de formData
        setFormData({
          name: "",
          comment: "",
        });

        // Recargar los comentarios después de enviar un nuevo comentario
      } else {
        console.error("Error al enviar el comentario:", response.statusText);
        // Puedes manejar el error según tus necesidades
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Handle validation errors
        const errors = error.response.data.errors;
        Object.values(errors).forEach((error) => {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: error[0],
          });
        });
      }
    }
  };

  const handleApprove = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
    });

    if (result.isConfirmed) {
      // Lógica de aprobación aquí
      try {
        const response = await axios.post(`/api/comment/approve/${id}`);

        if (response.status === 200) {
          // Actualizar comentarios después de la aprobación
          // ...

          // o actualizar la lista localmente según tu implementación
          // Ejemplo de cómo podrías hacerlo:
          const updatedComments = adminPost.comments.map((comment) =>
            comment.id === id ? { ...comment, approved: true } : comment
          );
          setAdminPost({ ...adminPost, comments: updatedComments });

          Swal.fire({
            icon: "success",
            title: "Comment Approved!",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          console.error("Error approving comment:", response.statusText);
        }
      } catch (error) {
        console.error("Error approving comment:", error);
      }
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      // Lógica de eliminación aquí
      try {
        const response = await axios.delete(`/api/comment/delete/${id}`);

        if (response.status === 200) {
          // Actualizar comentarios después de la eliminación
          // ...
          const updatedComments = adminPost.comments.filter(
            (comment) => comment.id !== id
          );

          setAdminPost({ ...adminPost, comments: updatedComments });

          Swal.fire({
            icon: "success",
            title: "Comment Deleted!",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          console.error("Error deleting comment:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    }
  };

  const handleShow = (title, postId) => {
    const encodedTitle = encodeURIComponent(title);
    // Redirigir al usuario a la página de edición con el título en la URL
    history.push(`/Blog/${encodedTitle}`, postId);
  };

  return (
    <>
      {showDiv && <Form close={close} selectedOption={selectedOption} />}

      {post ? (
        <div className="relative flex flex-col w-full ">
          <div className="z-30 justify-end hidden w-full h-16 lg:flex">
            <div className="z-30 flex items-center w-40 gap-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61553913384093"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fb} className="w-5" alt="" />
              </a>
              <a
                href="https://www.instagram.com/techxus501c"
                target="_blank"
                rel="noreferrer"
              >
                <img src={insta} className="w-5" alt="" />
              </a>
              <a
                href="https://twitter.com/techxus501c"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} className="w-5" alt="" />
              </a>
            </div>
          </div>

          <div className="relative z-20 flex items-center justify-between w-full h-20 p-4 pt-4 shadow-xl lg:shadow-none lg:border-t lg:border-b lg:border-black">
            <Link to="/">
              <img src={Blogo} className="w-52" alt="logo" />
            </Link>
            <div className="w-auto h-20 ">
              <Navigation />
            </div>
          </div>

          <div className="flex flex-col w-full lg:flex-row">
            <div className="flex flex-col lg:w-3/4 lg:p-8 lg:gap-y-4">
              <div className="flex flex-col w-full p-6 mt-10 ">
                <div className="w-full post-image">
                  <img
                    src={`https://api.techxus.org/images/${post.file}`}
                    alt="hey"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="flex flex-col p-4 bg-gris gap-y-6">
                  <div className="lg:pt-5">
                    <h1 className="text-lg font-semibold lg:text-3xl">
                      {post.title}
                    </h1>
                  </div>
                  <div className="flex w-full gap-x-2">
                    {/* <div>
                    <img src={profile} className="w-10" alt="" />
                  </div> */}
                    <div className="flex justify-between w-full">
                      <div className="flex flex-col justify-between">
                        <h3 className="text-sm font-semibold">Daniel Acquah</h3>
                        <h4 className="text-xs font-medium">
                          {post && moment(post.created_at).format("YYYY-MM-DD")}
                        </h4>
                      </div>
                      <div className="flex gap-x-4">
                        <img src={fbgris} alt="" />
                        <img src={instagris} alt="" />
                        <img src={twit} className="w-4" alt="" />
                      </div>
                    </div>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                </div>

                <div className="flex flex-col mt-10 gap-y-4">
                  <div>
                    <h2 className="text-xl font-semibold">Comments</h2>
                  </div>

                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-y-4"
                  >
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="w-full pl-4 border rounded-lg h-14 border-gris6"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      placeholder="Write your comment"
                      className="w-full h-40 pl-4 border rounded-lg border-gris6"
                      name="comment"
                      value={formData.comment}
                      onChange={handleChange}
                    />

                    <div>
                      <button className="flex items-center justify-center text-sm text-white rounded-full w-52 h-14 bg-azul">
                        Publish Comment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {isAdmin && adminPost ? (
                <>
                  <div className="flex items-center justify-between w-full p-6">
                    <h2 className="text-xl font-semibold">Read Comments</h2>
                    <div className="flex items-center gap-x-2">
                      <img src={comments} alt="" />
                      <h4 className="text-xs font-medium text-negro3">
                        {" "}
                        {adminPost.comments
                          ? adminPost.comments.length
                          : 0}{" "}
                        {adminPost.comments && adminPost.comments.length === 1
                          ? "Comment"
                          : "Comments"}
                      </h4>
                    </div>
                  </div>

                  {adminPost.comments &&
                    adminPost.comments
                      .slice(0, visibleComments)
                      .map((comment, index) => (
                        <Comments
                          key={index}
                          name={comment.name}
                          date={moment(comment.created_at).format("YYYY/MM/DD")}
                          comment={comment.comment}
                          showButtons={true}
                          handleApprove={() => handleApprove(comment.id)}
                          handleDelete={() => handleDelete(comment.id)}
                          approved={comment.approved}
                        />
                      ))}

                  {visibleComments <
                    (adminPost.comments ? adminPost.comments.length : 0) && (
                    <div className="flex items-center justify-center w-full">
                      <button
                        onClick={handleLoadMore}
                        className="flex items-center justify-center w-40 font-semibold text-white rounded-full h-14 bg-azul"
                      >
                        Load more
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="flex items-center justify-between w-full p-6">
                    <h2 className="text-xl font-semibold">Read Comments</h2>
                    <div className="flex items-center gap-x-2">
                      <img src={comments} alt="" />
                      <h4 className="text-xs font-medium text-negro3">
                        {" "}
                        {post.comments ? post.comments.length : 0}{" "}
                        {post.comments && post.comments.length === 1
                          ? "Comment"
                          : "Comments"}
                      </h4>
                    </div>
                  </div>

                  {post.comments &&
                    post.comments
                      .slice(0, visibleComments)
                      .map((comment, index) => (
                        <Comments
                          key={index}
                          name={comment.name}
                          date={moment(comment.created_at).format("YYYY/MM/DD")}
                          comment={comment.comment}
                        />
                      ))}

                  {visibleComments <
                    (post.comments ? post.comments.length : 0) && (
                    <div className="flex items-center justify-center w-full">
                      <button
                        onClick={handleLoadMore}
                        className="flex items-center justify-center w-40 font-semibold text-white rounded-full h-14 bg-azul"
                      >
                        Load more
                      </button>
                    </div>
                  )}
                </>
              )}

              <div className="hidden w-full p-6 mt-5">
                <div className="flex flex-col p-4 gap-y-4 bg-gris9">
                  <div>
                    <h2 className="text-lg font-bold lg:text-2xl">
                      About the author
                    </h2>
                  </div>
                  <div className="flex items-center gap-x-4">
                    <div>
                      <img src={profile} className="w-16" alt="" />
                    </div>

                    <div className="flex justify-between w-full">
                      <div className="flex flex-col gap-y-2">
                        <h4 className="text-sm font-semibold text-negro5">
                          Daniel Acquah
                        </h4>
                        <h5 className="text-xs font-normal text-negro4">
                          Number of total blogs here ...
                        </h5>
                      </div>

                      <div className="flex gap-x-4">
                        <a
                          href="https://www.facebook.com/profile.php?id=61553913384093"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img className="w-6 lg:w-10" src={facebook} alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/techxus501c"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={instagram} className="w-6 lg:w-10" alt="" />
                        </a>
                        <a
                          href="https://twitter.com/techxus501c"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={twit} className="w-6 lg:w-10" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium lg:text-base">
                      Here shoulg do a little intro about yourself cause you
                      llbe the author of all blogs + picture
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:w-1/4">
              <div className="pl-6 mt-10">
                <h2 className="text-xl font-semibold">Related Blogs</h2>
              </div>

              {randomPost.map((post, index) => (
                <Related
                  key={index}
                  date={moment(post.created_at).format("YYYY-MM-DD")}
                  title={post.title}
                  image={`https://api.techxus.org/images/${post.file}`}
                  handleShow={() => handleShow(post.title, { postId: post.id })}
                />
              ))}
            </div>
          </div>

          <Footer
            handle={handleLinkClick}
            setSelectedOption={setSelectedOption}
          />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}
