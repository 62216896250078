import React, { useState, useRef } from "react";
import mefy from "../assets/Images/program-1.jpg";
import segunda from "../assets/Images/program-2.jpg";
import tercera from "../assets/Images/program-3.jpg";
import cuarta from "../assets/Images/program-4.jpg";
import Program from "./Program";
import VideoPrograms from "./VideoPrograms";

export default function Programs({ handleLinkClick, setSelectedOption }) {
  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false);
  const videoRef = useRef(null);

  const openVideoPlayer = () => {
    setIsVideoPlayerVisible(true);
  };

  const closeVideoPlayer = () => {
    setIsVideoPlayerVisible(false);
  };

  const programData = [
    {
      title: "ITmefy",
      text: "For Non-IT graduates seeking a career switch. ITmefy: Your pathway to tech expertise and a new professional journey. Equips you with the expertise needed to thrive in the tech industry.",
      date: "01/15/2023 ",
      image: mefy,
      videoSrc: "../assets/Video/aboutus-video.mp4", // Ruta del video para ITmefy
    },
    {
      title: "Technergy",
      text: "Ideal for those who've studied IT but seek focused learning to stay ahead in the ever-evolving tech landscape. So elevate your expertise and stay competitive.",
      date: "01/15/2023 ",
      image: segunda,
      videoSrc: "../assets/Video/technergy-video.mp4", // Ruta del video para Technergy
    },
    {
      title: "ITelevate",
      text: "Tailored for IT students hungry for real-world readiness, Technergy bridges the gap between academia and industry demands. Designed for those whose college curriculum left gaps.",
      date: "01/15/2023 ",
      image: tercera,
      videoSrc: "../assets/Video/itelevate-video.mp4", // Ruta del video para ITelevate
    },
    {
      title: "LearnView",
      text: "Built for students and their parents, this program serves as a comprehensive guide, providing insights and clarity to help make informed decisions about studying IT in college.",
      date: "01/15/2023 ",
      image: cuarta,
      videoSrc: "../assets/Video/learnview-video.mp4", // Ruta del video para LearnView
    },
    // Agrega más objetos según sea necesario
  ];

  return (
    <>
      <div id="programs" className="flex flex-col w-full p-4 mt-10 gap-y-6">
        {/* ... Resto de tu código ... */}

        <div className="w-8/12 h-0.5 mx-auto my-0 mt-10 mb-10 bg-gris13"></div>

        <div className="">
          <h1 className="text-3xl font-semibold text-center uppercase">
            Our Programs
          </h1>
        </div>

        <div className="flex flex-col lg:flex-row gap-x-4 gap-y-4 ">
          {programData.map((program, index) => (
            <Program
              handle={handleLinkClick}
              setSelectedOption={setSelectedOption}
              showVideoPlayer={openVideoPlayer}
              key={index}
              title={program.title}
              text={program.text}
              date={program.date}
              image={program.image}
              videoRef={videoRef}
              videoSrc={program.videoSrc}
            />
          ))}
        </div>
      </div>
      {isVideoPlayerVisible && (
        <VideoPrograms
          isOpen={isVideoPlayerVisible}
          onClose={closeVideoPlayer}
          videoRef={videoRef}
        />
      )}{" "}
    </>
  );
}
