import { useState } from "react";
import cross from "../assets/Icons/cross-circle.svg";
import axios from "axios";
import Swal from "sweetalert2";
export default function Form({ close, setSelectedOption, selectedOption }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    reason: selectedOption,
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("api/form", formData);

      // Handle the response as needed
      console.log("Server response:", response.data);

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Form submitted successfully.",
        });
      }
      // You can close the form or perform other actions
      close();
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Handle validation errors
        const errors = error.response.data.errors;
        Object.values(errors).forEach((error) => {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: error[0],
          });
        });
      } else {
        // Handle other errors
        console.error("Error submitting form:", error);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  return (
    <>
      <div className="absolute z-30 flex items-center justify-center w-full h-screen">
        <div className="absolute z-20 flex items-center justify-center w-full h-full bg-gray-500 opacity-50 "></div>

        <div className="absolute z-40 flex w-11/12 h-auto p-5 mx-auto my-0 mt-10 bg-white shadow-xl lg:p-10 lg:w-2/3 rounded-xl">
          <div className="flex flex-col w-full gap-y-4">
            <div className="flex items-center justify-between w-full">
              <h2 className="text-xl font-bold lg:text-3xl">
                Get in touch here
              </h2>
              <img src={cross} alt="" onClick={close} />
            </div>
            <div>
              <h3 className="font-medium text-gris5">
                Looking forward to connecting with you!
              </h3>
            </div>
            <div>
              <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full pl-4 border rounded-lg h-14 text-gris7 border-gris6"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />

                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  className="w-full pl-4 border rounded-lg h-14 text-gris7 border-gris6"
                  value={formData.email}
                  onChange={handleInputChange}
                />

                <input
                  type="text"
                  placeholder="Phone Number"
                  className="w-full pl-4 border rounded-lg h-14 text-gris7 border-gris6"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />

                <div className="flex items-center w-full pr-4 border rounded-lg outline-none h-14 text-gris7 border-gris6">
                  <select
                    name="reason"
                    className="w-full pl-4 border-0 border-none outline-none h-4/5 custom-select text-gris6 focus:border-gris6 focus:border-0 focus:border-none active:border-0 after:outline-none focus:ring-0 before:outline-none ring-transparent before:border-none"
                    id=""
                    value={selectedOption || ""}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    disabled={selectedOption !== ""}
                  >
                    <option value="" disabled={selectedOption !== ""}>
                      Select an option
                    </option>
                    <option
                      value="Report Issue"
                      disabled={selectedOption === "reportIssue"}
                    >
                      Report Issue
                    </option>
                    <option
                      value="Feedback"
                      disabled={selectedOption === "feedback"}
                    >
                      Feedback
                    </option>
                    <option
                      value="Suggestions"
                      disabled={selectedOption === "Suggestions"}
                    >
                      Suggestions
                    </option>
                    <option
                      value="Volunteer"
                      disabled={selectedOption === "Volunteer"}
                    >
                      Volunteer
                    </option>
                    <option
                      value="Partner"
                      disabled={selectedOption === "Partner"}
                    >
                      Partner
                    </option>
                    <option
                      value="Subscribe"
                      disabled={selectedOption === "Subscribe"}
                    >
                      Subscribe
                    </option>
                    <option
                      value="Contact Us"
                      disabled={selectedOption === "ContactUs"}
                    >
                      Contact Us
                    </option>
                  </select>
                </div>

                <input
                  type="text"
                  className="w-full h-32 pl-4 border rounded-lg border-gris6"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleInputChange}
                />

                <button className="flex items-center justify-center h-12 font-normal text-white rounded-full w-44 bg-azul">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
