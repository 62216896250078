import fb from "../assets/Email/facebook (1).svg";
import logo from "../assets/logo/Blue Logo.svg";
import girl from "../assets/Email/Group 39311.jpg";
import check from "../assets/Email/check-circle.svg";
import girl2 from "../assets/Email/pexels-rodnae-productions-10375434 (1080p).jpg";
import facebook from "../assets/Email/Group 39313.svg";
import twitter from "../assets/Email/Group 39312.svg";
import instagram from "../assets/Email/Group 39314.svg";
import linkedin from "../assets/Email/Group 39315.svg";
import youtube from "../assets/Email/Group 39316.svg";
export default function Correo() {
  return (
    <>
      <div className="flex flex-col w-full min-h-screen">
        <div className="flex justify-between w-full h-20 px-5 bg-white border-b-2 lg:px-10 border-b-azul">
          <img src={logo} className="w-40 lg:w-auto" alt="" />
          <div className="flex w-auto gap-x-4">
            <img src={fb} alt="" />
            <img src={fb} alt="" />
            <img src={fb} alt="" />
          </div>
        </div>
        <div className="relative flex w-full h-80">
          <div className="absolute z-0 block w-full h-full">
            <img src={girl} className="object-cover w-full h-full" alt="" />
          </div>
          <div className="z-10 flex flex-col items-center justify-center w-full gap-y-4">
            <div className="w-2/3 mx-auto my-0">
              {" "}
              <h1 className="text-xl font-bold text-center text-white">
                Thank you for getting in touch with Techxus!
              </h1>
            </div>
            <div className="w-3/4 mx-auto my-0">
              {" "}
              <h2 className="text-sm text-center text-white">
                Your inquiry has been received, and we will respond to you at
                the earliest convenience!
              </h2>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full h-auto">
          <div className="py-5 text-center">
            <p className="font-bold">Our Services</p>
          </div>
          <div className="flex justify-between w-11/12 py-5 mx-auto my-0 lg:justify-evenly">
            <div className="flex flex-col text-xs gap-y-6">
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">Career Services</p>
              </div>
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">Events</p>
              </div>
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">Team & Collaboration</p>
              </div>
            </div>
            <div className="flex flex-col text-xs gap-y-6">
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">Mentorship</p>
              </div>
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">Hands On Projects</p>
              </div>
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">Internship & Jobs</p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center py-5">
            <button className="flex items-center justify-center h-10 text-sm text-white rounded-full w-44 bg-azul">
              Visit our website
            </button>
          </div>
        </div>

        <div className="relative flex w-full h-80">
          <div className="absolute z-0 block w-full h-full">
            <img src={girl2} className="object-cover w-full h-full" alt="" />
          </div>
          <div className="z-10 flex flex-col items-center justify-center w-full gap-y-4">
            <div className="w-full mx-auto my-0">
              {" "}
              <h1 className="text-2xl font-semibold text-center text-white">
                Empowering Minorities <br /> Through Technology Training <br />{" "}
                & Education
              </h1>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full h-auto">
          <div className="py-5 text-center">
            <p className="font-bold">Our Programs</p>
          </div>
          <div className="flex justify-between w-5/6 py-5 mx-auto my-0 lg:justify-evenly">
            <div className="flex flex-col text-sm gap-y-6">
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">ITmefy</p>
              </div>
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">ITelevate</p>
              </div>
            </div>
            <div className="flex flex-col text-sm gap-y-6">
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">Techenergy</p>
              </div>
              <div className="flex items-center gap-x-4">
                <img src={check} alt="" />
                <p className="font-semibold">Learn View</p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center py-5">
            <button className="flex items-center justify-center h-10 text-sm text-white rounded-full w-44 bg-azul">
              Visit our website
            </button>
          </div>
        </div>

        <div className="flex flex-col items-center px-8 py-10 bg-azul gap-y-6">
          <h1 className="text-xl font-semibold text-center text-white lg:text-2xl">
            Donate to Techxus!
          </h1>
          <div className="mx-auto my-0 lg:w-4/6">
            <h2 className="text-sm font-normal text-center text-white lg:text-lg">
              Your donation to Techxus today will secure the future of
              underprivileged IT experts. Rest assured, we will ensure that your
              contribution is used wisely.
            </h2>
          </div>

          <button className="h-10 text-sm bg-white rounded-full w-44 lg:h-12 lg:w-60 text-azul ">
            Donate us{" "}
          </button>
        </div>

        <div className="flex flex-col justify-between w-full h-40 lg:h-52 px-5 pt-5 lg:px-20 mb-2.5">
          <div className="flex justify-center w-3/5 mx-auto my-0 gap-x-4">
            <img src={facebook} className="w-8 lg:w-12" alt="" />
            <img src={twitter} className="w-8 lg:w-12" alt="" />
            <img src={instagram} className="w-8 lg:w-12" alt="" />
            <img src={linkedin} className="w-8 lg:w-12" alt="" />
            <img src={youtube} className="w-8 lg:w-12" alt="" />
          </div>
          <div className="mx-auto my-0 text-xs text-center lg:w-3/6 lg:text-base text-gris7 ">
            <p>
              Received this email without having contacted us? Please{" "}
              <span className="text-azul">report it here</span> . If you no
              longer wish to receive emails from us in the future, you can{" "}
              <span className="text-azul">unsubscribe from our newsletter</span>{" "}
              .
            </p>
          </div>

          <div className="w-full text-xs font-bold text-center">
            <p>Copyright 2024 © Techxus</p>
          </div>
        </div>
      </div>{" "}
    </>
  );
}
