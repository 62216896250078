import { Link } from "react-router-dom";
import fb from "../assets/Icons/fbs.svg";
import insta from "../assets/Icons/instagram.svg";
import twitter from "../assets/Icons/twitter-alt.svg";
import Wlogo from "../assets/logo/Blue Logo.svg";
import Donate from "../components/Donate";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import { useEffect, useState } from "react";
import Form from "../components/Form";
export default function DonationsForm() {
  useEffect(() => {
    // Esta función se ejecutará cuando el componente se monte (cuando la página se cargue)
    window.scrollTo(0, 0); // Esto lleva al usuario al principio de la página
  }, []);

  const [showDiv, setShowDiv] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Esto lleva al usuario al principio de la página

    setShowDiv(!showDiv); // Cambia el estado o realiza la acción necesaria
  };
  const close = () => {
    setShowDiv(false); // Función para cerrar la div
  };
  return (
    <>
      {showDiv && <Form close={close} selectedOption={selectedOption} />}

      <div className="relative flex flex-col w-full h-96">
        <div className="absolute z-10 w-full h-full bg-black opacity-50"></div>

        <div className="absolute z-0 w-full h-full bg-center bg-no-repeat bg-cover bg-donar"></div>

        <div className="z-30 justify-end hidden w-full h-16 lg:flex">
          <div className="z-30 flex items-center w-40 gap-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=61553913384093"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb} className="w-5" alt="" />
            </a>
            <a
              href="https://www.instagram.com/techxus501c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} className="w-5" alt="" />
            </a>
            <a
              href="https://twitter.com/techxus501c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} className="w-5" alt="" />
            </a>
          </div>
        </div>

        <div className="relative z-20 flex items-center justify-between w-full h-20 p-4 pt-4 lg:border-t lg:border-b lg:border-white">
          <Link to="/">
            <img src={Wlogo} className="w-52" alt="logo" />
          </Link>
          <div className="w-auto h-20 ">
            <Navigation
              handle={handleLinkClick}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>

        <div className="flex items-center justify-center flex-grow w-full">
          <h1 className="z-10 text-2xl font-semibold text-white lg:text-5xl">
            Donate
          </h1>
        </div>
      </div>

      <Donate />

      <Footer handle={handleLinkClick} setSelectedOption={setSelectedOption} />
    </>
  );
}
