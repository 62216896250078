export default function Related({ date, title, image, handleShow }) {
  return (
    <>
      <div className="flex flex-col w-full p-4">
        <div className="flex flex-col p-4 border rounded lg:p-2 border-gris10 gap-y-2">
          <div className="w-full h-52">
            <img src={image} className="object-cover w-full h-full" alt="" />
          </div>

          <div>
            <h3 className="text-sm">{date}</h3>
          </div>

          <button onClick={handleShow}>
            <h2 className="text-lg font-semibold text-left">{title}</h2>
          </button>
        </div>
      </div>
    </>
  );
}
