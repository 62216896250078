import { CardElement } from "@stripe/react-stripe-js";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "#0000000",
      color: "#000000",

      fontWeight: "500",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#000000",
      },
    },
    invalid: {
      iconColor: "#FF0000",
      color: "#800020",
    },
  },
};

export default function CreditCard() {
  return (
    <>
      <div className="w-full px-5 py-5 mx-auto my-0 bg-white border rounded border-gris6 ">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
    </>
  );
}
