import React, { useRef, useEffect, useState } from "react";

import katrina from "../assets/Images/service-1.jpg";
import viejos from "../assets/Images/service-3.jpg";
import rie from "../assets/Images/service-6.jpg";
import clase from "../assets/Images/service-5.jpg";
import ordenadores from "../assets/Images/service-4.jpg";
import vieja from "../assets/Images/service-2.jpg";
import climbing from "../assets/Icons/climbing.svg";
import estrella from "../assets/Icons/estrella.svg";
import maleta from "../assets/Icons/maletin.svg";
import cerebro from "../assets/Icons/cerebro.svg";
import mentor from "../assets/Icons/mentor.svg";
import pipol from "../assets/Icons/pipol.svg";
import Service from "./Service";
import Form from "./Form";
export default function Services({ handleLinkClick, setSelectedOption }) {
  const imageRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    if (imageRef.current) {
      // Obtener la altura de la imagen
      const height = imageRef.current.clientHeight;
      setImageHeight(height);
    }
  }, []);

  const servicesData = [
    {
      title: "CAREER SERVICES",
      text: "At Techxus, our commitment to your success doesn't end with education; it extends into your professional journey. Our Career Services team is your trusted partner in shaping a rewarding and fulfilling career in the tech industry.",
      foto1: katrina,
      foto2: climbing,
    },
    {
      title: "MENTORSHIP",
      text: "At Techxus, mentorship is at the heart of our commitment to your growth and success. We firmly believe that learning extends far beyond the classroom, and having a trusted mentor by your side can make all the difference in your journey to excellence.",
      foto1: vieja,
      foto2: mentor,
    },

    {
      title: "EVENTS",
      text: "At Techxus, we understand that education isn't confined to textbooks and classrooms. It's about immersive experiences, hands-on learning, and connecting with the vibrant tech community. That's why our events are designed to be more than just gatherings.",
      foto1: viejos,
      foto2: estrella,
    },

    {
      title: "HANDS ON PROJECTS",
      text: "Dive into the heart of technology as we provide immersive, practical projects that allow you to apply your newfound skills in real-world scenarios. From coding challenges to innovative solutions, our hands-on approach ensures that you not only understand the theory but also gain valuable experience.",
      foto1: ordenadores,
      foto2: cerebro,
    },

    {
      title: "TEAM & COLLABORATION",
      text: "At Techxus, collaboration is at the core of our success. Our dedicated team, hailing from diverse backgrounds and experiences, unites under a common goal: to empower minorities through technology. We believe in the strength of teamwork, innovation, and mutual support.",
      foto1: clase,
      foto2: pipol,
    },

    {
      title: "INTERNSHIP & JOBS",
      text: "At Techxus, we understand that education isn't confined to textbooks and classrooms. It's about immersive experiences, hands-on learning, and connecting with the vibrant tech community. That's why our events are designed to be more than just gatherings.",
      foto1: rie,
      foto2: maleta,
    },

    // Agrega más objetos de datos según sea necesario
  ];

  return (
    <>
      <div className="flex flex-col w-full mt-10">
        <div
          id="services"
          className="flex flex-col items-center justify-center pt-10 pb-6 bg-gris gap-y-6"
        >
          <h2 className="text-3xl font-semibold text-negro">OUR SERVICES</h2>

          <h3 className="text-base font-semibold leading-snug text-center text-black lg:hidden">
            Nurturing Potential <br /> through Technology <br /> Education
          </h3>
          <h3 className="hidden text-base font-normal leading-snug text-center text-black lg:block">
            Nurturing Potential through Technology Education
          </h3>
        </div>

        <div className="flex flex-col w-full mt-10">
          <div className="flex flex-col w-full h-auto gap-y-1 lg:grid lg:grid-cols-3 lg:gap-x-1">
            {servicesData.map((service, index) => (
              <Service
                key={index}
                handle={handleLinkClick}
                setSelectedOption={setSelectedOption}
                title={service.title}
                text={service.text}
                foto1={service.foto1}
                foto2={service.foto2}
                imageRef={imageRef}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
