import Navigation from "../components/Navigation";
import Wlogo from "../assets/logo/Blue Logo.svg";
import backgroundVideo from "../assets/Video/video.mp4";
import About from "../components/About";
import Services from "../components/Services";
import Programs from "../components/Programs";
import Reviews from "../components/Reviews";
import Blogs from "../components/Blogs";
import Footer from "../components/Footer";
import fb from "../assets/Icons/fbs.svg";
import insta from "../assets/Icons/instagram.svg";
import twitter from "../assets/Icons/twitter-alt.svg";
import Form from "../components/Form";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import HashLinkObserver from "react-hash-link";
export default function Homepage() {
  const [showDiv, setShowDiv] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Esto lleva al usuario al principio de la página

    setShowDiv(!showDiv); // Cambia el estado o realiza la acción necesaria
  };
  const close = () => {
    setShowDiv(false); // Función para cerrar la div
  };

  return (
    <>
      <HashLinkObserver />

      {showDiv && <Form close={close} selectedOption={selectedOption} />}
      <div className="relative flex flex-col w-full min-h-screen">
        <div className="z-20 justify-end hidden w-full h-16 lg:flex">
          <div className="flex items-center w-40 gap-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=61553913384093"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb} className="w-5" alt="" />
            </a>
            <a
              href="https://www.instagram.com/techxus501c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} className="w-5" alt="" />
            </a>
            <a
              href="https://twitter.com/techxus501c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} className="w-5" alt="" />
            </a>
          </div>
        </div>
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 object-cover w-full h-full "
        >
          <source src={backgroundVideo} type="video/mp4" />
          Tu navegador no admite la reproducción de video.
        </video>
        <div className="absolute w-full h-screen bg-black opacity-50"></div>
        <div className="relative flex items-center justify-between w-full h-20 p-4 pt-4 lg:border-t lg:border-b lg:border-white">
          <Link to="/">
            <img src={Wlogo} className="w-52" alt="logo" />
          </Link>
          <div className="w-auto h-20 ">
            <Navigation
              handle={handleLinkClick}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>

        <div className="z-10 flex flex-col justify-center flex-grow w-full p-8 gap-y-6">
          <div>
            <h1 className="text-2xl font-semibold leading-normal text-center text-white lg:hidden font-montserrat">
              Empowering Minorities Through Technology Training & Education
            </h1>
            <h1 className="hidden text-5xl font-semibold leading-normal text-center text-white lg:block font-montserrat">
              Empowering Minorities Through <br /> Technology Training & <br />{" "}
              Education
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center w-full gap-x-4 lg:flex-row gap-y-4">
            <NavHashLink
              to="/#programs"
              className="flex items-center justify-center text-base font-medium text-white rounded-full w-44 h-14 font-montserrat bg-azul"
            >
              Get Started
            </NavHashLink>
            <HashLink
              to="/#about"
              className="flex items-center justify-center text-base font-medium text-white bg-transparent border border-white rounded-full w-44 h-14 font-montserrat"
              smooth
            >
              About Us{" "}
            </HashLink>
          </div>
        </div>
      </div>
      <About />

      <Services
        handleLinkClick={handleLinkClick}
        setSelectedOption={setSelectedOption}
      />
      <Programs
        handleLinkClick={handleLinkClick}
        setSelectedOption={setSelectedOption}
      />
      <Reviews />
      <Blogs />
      <Footer handle={handleLinkClick} setSelectedOption={setSelectedOption} />
    </>
  );
}
