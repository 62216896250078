import fb from "../assets/Icons/fb.svg";
import twitter from "../assets/Icons/black-x.svg";
import linkedin from "../assets/Icons/linkedin.svg";
import instagram from "../assets/Icons/insta-c.svg";
import whatsapp from "../assets/Icons/whatsapp.svg";
import youtube from "../assets/Icons/youtube.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
export default function Footer({ handle, selectedOption, setSelectedOption }) {
  const handleOptionClick = (option) => {
    console.log("Setting selected option:", option);
    setSelectedOption(option);
    handle(); // Assuming this function is responsible for closing the menu
  };
  return (
    <>
      <div className="flex flex-col justify-between w-full pt-16 pl-6 pr-6 mt-10 bg-cover bg-footer gap-y-6">
        <div className="flex flex-col w-full lg:mb-32 lg:flex-row gap-y-12">
          <div className="flex flex-col w-full lg:w-1/3 gap-y-4 lg:mr-20 lg:gap-y-10">
            {/* <div className="flex flex-col gap-y-4">
              <div className="flex items-center w-full gap-x-4">
                <h2 className="text-lg font-bold text-white">Share</h2>
              </div>
              <div className="grid w-full grid-cols-5 lg:flex gap-y-4 gap-x-4">
                <a href="/" className="">
                  {" "}
                  <img src={fb} className="" alt="" />
                </a>
                <a href="/" className="">
                  {" "}
                  <img src={twitter} className="" alt="" />
                </a>
                <a href="/" target="_blank" rel="noreferrer" className="">
                  {" "}
                  <img src={email} className="" alt="" />
                </a>
                <a href="/" className="">
                  {" "}
                  <img src={whatsapp} className="" alt="" />
                </a>
                <a href="/" className="">
                  {" "}
                  <img src={messenger} className="" alt="" />
                </a>
              </div>
            </div> */}

            <div className="flex flex-col gap-y-4">
              <div>
                <h2 className="text-lg font-bold text-white">Follow Us</h2>
              </div>

              <div className="grid grid-cols-6 lg:gap-x-4 lg:flex">
                <a
                  href="https://www.facebook.com/profile.php?id=61553913384093"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fb} alt="" />
                </a>

                <a
                  href="https://twitter.com/techxus501c"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/techxus/?viewAsMember=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={linkedin} alt="" />
                </a>

                <a
                  href="https://youtube.com/@Techxus?si=MtTY-G91i5jqAevG"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={youtube} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/techxus501c"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={instagram} alt="" />
                </a>
                <a
                  href="https://wa.me/message/OOO3QTDJCRLYN1"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={whatsapp} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-around w-full gap-y-6 lg:flex-row lg:gap-x-20">
            <div className="flex lg:hidden gap-x-4 lg:gap-x-20">
              <div className="flex flex-col w-48 lg:w-auto gap-y-2">
                <div className="text-lg font-bold text-white">
                  <h2>Legal</h2>
                </div>

                <div className="flex flex-col text-base text-white font-extralight gap-y-2">
                  <Link to="/Terms&Conditions" target="_blank">
                    Terms & Conditions
                  </Link>
                  <Link to="/Terms&Conditions" target="_blank">
                    Privacy
                  </Link>
                  <Link to="/Terms&Conditions" target="_blank">
                    Copyright
                  </Link>
                </div>
              </div>

              <div className="flex flex-col gap-y-2">
                <div className="text-lg font-bold text-white">
                  <h2>Contact Us</h2>
                </div>

                <div className="flex flex-col text-base text-white font-extralight gap-y-2">
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Report Issue");
                      handle();
                    }}
                  >
                    Report Issue
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Feedback");
                      handle();
                    }}
                  >
                    Feedback
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Suggestions");
                      handle();
                    }}
                  >
                    Suggestions
                  </Link>

                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Contact Us");
                      handle();
                    }}
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex gap-x-4 lg:hidden lg:gap-x-20">
              <div className="flex flex-col w-48 lg:w-auto gap-y-2">
                <div className="text-lg font-bold text-white">
                  <h2>Get Involved</h2>
                </div>

                <div className="flex flex-col text-base text-white font-extralight gap-y-2">
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Volunteer");
                      handle();
                    }}
                  >
                    Volunteer
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Partner");
                      handle();
                    }}
                  >
                    Partner
                  </Link>
                  <Link to="/Donation">Donate</Link>
                </div>
              </div>

              <div className="flex flex-col gap-y-2">
                <div className="text-lg font-bold text-white">
                  <h2>About us</h2>
                </div>

                <div className="flex flex-col text-base text-white font-extralight gap-y-2">
                  <HashLink smooth to="/#about">
                    Mission
                  </HashLink>
                  <HashLink smooth to="/#about">
                    Vision
                  </HashLink>
                  <HashLink smooth to="/#about">
                    501c Certified
                  </HashLink>
                </div>
              </div>
            </div>
            <div className="hidden w-full lg:flex justify-evenly gap-x-4 lg:gap-x-20">
              <div className="flex flex-col w-48 lg:w-auto gap-y-2">
                <div className="text-lg font-bold text-white">
                  <h2>Legal</h2>
                </div>

                <div className="flex flex-col text-base text-white font-extralight gap-y-2">
                  <Link to="/Terms&Conditions" target="_blank">
                    Terms & Conditions
                  </Link>
                  <Link to="/Terms&Conditions" target="_blank">
                    Privacy
                  </Link>
                  <Link to="/Terms&Conditions" target="_blank">
                    Copyright
                  </Link>
                </div>
              </div>

              <div className="flex flex-col gap-y-2">
                <div className="text-lg font-bold text-white">
                  <h2>Contact Us</h2>
                </div>

                <div className="flex flex-col text-base text-white font-extralight gap-y-2">
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Report Issue");
                      handle();
                    }}
                  >
                    Report Issue
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Feedback");
                      handle();
                    }}
                  >
                    Feedback
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Suggestions");
                      handle();
                    }}
                  >
                    Suggestions
                  </Link>

                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Contact Us");
                      handle();
                    }}
                  >
                    Contact us
                  </Link>
                </div>
              </div>
              <div className="flex flex-col w-48 lg:w-auto gap-y-2">
                <div className="text-lg font-bold text-white">
                  <h2>Get Involved</h2>
                </div>

                <div className="flex flex-col text-base text-white font-extralight gap-y-2">
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Volunteer");
                      handle();
                    }}
                  >
                    Volunteer
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      handleOptionClick("Partner");
                      handle();
                    }}
                  >
                    Partner
                  </Link>
                  <Link to="/Donation">Donate</Link>
                </div>
              </div>

              <div className="flex flex-col gap-y-2">
                <div className="text-lg font-bold text-white">
                  <h2>About us</h2>
                </div>

                <div className="flex flex-col text-base text-white font-extralight gap-y-2">
                  <HashLink smooth to="/#about">
                    Mission
                  </HashLink>
                  <HashLink smooth to="/#about">
                    Vision
                  </HashLink>
                  <HashLink smooth to="/#about">
                    501c Certified
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col mb-6 gap-y-4">
          <div className="w-full h-0.5 opacity-50 bg-white"></div>

          <div>
            <h4 className="text-center text-white">Copyright 2023 © Techxus</h4>
          </div>
        </div>
      </div>
    </>
  );
}
