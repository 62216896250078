import play from "../assets/Icons/play.svg";
import whiteplay from "../assets/Icons/button-white.svg";
export default function Program({
  image,
  title,
  date,
  text,
  handle,
  selectedOption,
  setSelectedOption,
  showVideoPlayer,
}) {
  const handleOptionClick = (option) => {
    console.log("Setting selected option:", option);
    setSelectedOption(option);
    handle(); // Assuming this function is responsible for closing the menu
  };
  return (
    <>
      <div className="flex flex-col w-full p-3 transition duration-500 ease-in-out rounded-lg shadow-xl group hover:bg-azul1 gap-y-4">
        <div className="">
          <img src={image} className="rounded-xl" alt="" />
        </div>

        <div className="flex justify-between ">
          <h2 className="text-base font-semibold transition duration-500 ease-in-out group-hover:text-white ">
            {title}
          </h2>
          <h4 className="text-sm font-medium transition duration-500 ease-in-out group-hover:text-white ">
            {date}
          </h4>
        </div>

        <div className="h-32">
          <h3 className="text-sm font-normal transition duration-500 ease-in-out text-gris1 group-hover:text-white ">
            {text}
          </h3>
        </div>

        <div className="flex flex-col items-center gap-y-2">
          <div
            className="flex items-center justify-center w-full h-12 transition duration-500 ease-in-out bg-white border-2 rounded-full group-hover:bg-azul border-azul gap-x-4"
            onClick={showVideoPlayer}
          >
            <div className="transition duration-500 ease-in-out group-hover:hidden">
              <img src={play} alt="" />
            </div>
            <div className="hidden transition duration-500 ease-in-out group-hover:block">
              <img
                src={whiteplay}
                className="transition duration-500 ease-in-out w-9"
                alt=""
              />
            </div>
            <h3 className="text-xs font-medium uppercase transition duration-500 ease-in-out text-azul group-hover:text-white">
              How it works
            </h3>
          </div>
          <button
            onClick={() => {
              handleOptionClick("Subscribe");
            }}
            className="flex items-center justify-center w-full h-12 text-xs font-medium text-white uppercase transition duration-500 ease-in-out rounded-full bg-azul group-hover:bg-white group-hover:text-azul"
          >
            Subscribe
          </button>
        </div>
      </div>
    </>
  );
}
