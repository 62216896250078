import fb from "../assets/Icons/fb.svg";
import linkedin from "../assets/Icons/link.svg";
import twitter from "../assets/Icons/black-x.svg";
import comillas from "../assets/Icons/comillas.svg";
export default function Review({ name, job, text, picture, link }) {
  return (
    <>
      <div className="flex flex-col h-auto p-6 pt-10 mx-auto my-0 mb-20 bg-white lg:w-4/12 2xl:w-3/12 lg:h-full gap-y-4 rounded-xl">
        <div className="flex items-center gap-x-4">
          <div>
            <img src={picture} className="w-10 h-10 rounded-full" alt="" />
          </div>

          <div className="flex flex-col justify-between">
            <h2 className="text-lg font-bold text-black">{name}</h2>
            <h4 className="text-sm font-normal text-gris2">{job}</h4>
          </div>
        </div>

        <div className="flex justify-end ">
          <img src={comillas} className="w-6" alt="" />
        </div>

        <div>
          <h3 className="text-sm italic font-semibold text-black">{text}</h3>
        </div>

        <div className="flex justify-center gap-x-4">
          <a
            href="https://www.facebook.com/profile.php?id=61553913384093"
            rel="noreferrer"
            target="_blank"
          >
            <img src={fb} alt="" />
          </a>
          <a
            href="https://twitter.com/techxus501c"
            rel="noreferrer"
            target="_blank"
          >
            <img src={twitter} alt="" />
          </a>
          <a href={link} rel="noreferrer" target="_blank">
            <img src={linkedin} alt="" />
          </a>
        </div>
      </div>
    </>
  );
}
