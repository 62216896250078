import like from "../assets/Icons/heart.svg";
import comment from "../assets/Icons/comments.svg";
import view from "../assets/Icons/eye.svg";
import facebook from "../assets/Icons/f.svg";
import instagram from "../assets/Icons/insta.svg";
import twitter from "../assets/Icons/icX.svg";

export default function Blog({
  picture,
  date,
  title,
  name,
  text,
  likes,
  comments,
  views,
  profile,
  handleShow,
}) {
  return (
    <>
      <div className="flex flex-col w-10/12 h-auto mx-auto my-0 mt-10 gap-y-4">
        <div className="w-full h-52">
          <img src={picture} className="object-cover w-full h-full" alt="" />
        </div>

        <div>
          <h5 className="text-xs font-medium text-negro3">{date}</h5>
        </div>

        <div className="w-full h-24 lg:px-2">
          <button onClick={handleShow}>
            <h2 className="text-xl font-semibold text-left ">{title}</h2>
          </button>
        </div>

        <div className="flex justify-between w-full lg:px-2">
          <div className="flex items-center gap-x-4">
            {/* <img src={profile} className="w-8 h-8 rounded-full" alt="" /> */}
            <h4 className="text-xs font-semibold ">{name}</h4>
          </div>
          <div className="flex items-center">
            <div className="flex gap-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61553913384093"
                rel="noreferrer"
                target="_blank"
              >
                <img src={facebook} alt="" />
              </a>
              <a
                href="https://twitter.com/techxus501c"
                rel="noreferrer"
                target="_blank"
              >
                <img className="w-4" src={twitter} alt="" />
              </a>
              <a
                href="https://www.instagram.com/techxus501c"
                rel="noreferrer"
                target="_blank"
              >
                <img src={instagram} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="h-32 lg:px-2">
          <h3 className="text-sm font-normal text-left text-gris3">{text}</h3>
        </div>
        <div className="hidden gap-x-6">
          <div className="flex items-center gap-x-2">
            <img src={like} alt="" />
            <h6 className="text-xs font-medium">{likes}</h6>
          </div>
          <div className="flex items-center gap-x-2">
            <img src={comment} alt="" />
            <h6 className="text-xs font-medium">{comments}</h6>
          </div>
          <div className="flex items-center gap-x-2">
            <img src={view} alt="" />
            <h6 className="text-xs font-medium">{views} views</h6>
          </div>
        </div>
      </div>
    </>
  );
}
