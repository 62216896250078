import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "../App.css";
import { useState } from "react";
import Swal from "sweetalert2";
export default function Subscription({ formData }) {
  const stripe = useStripe();
  const elements = useElements();
  const paymentElementOptions = {
    layout: "tabs",
  };
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      Swal.fire(
        "Error",
        "Payment service is not available. Please try again later.",
        "error"
      );
      return;
    }

    setIsLoading(true);

    // Intenta confirmar el pago
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        receipt_email: email,
        return_url: "https://techxus.org/PaymentCompleted",
      },
    });

    if (result.error) {
      console.error("Error during payment process:", result.error);
      Swal.fire("Error", result.error.message, "error");
      setIsLoading(false);
      return;
    }
  };

  return (
    <div className="w-4/5 p-6 mx-auto my-0">
      <input
        id="email"
        type="text"
        value={email}
        required
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
      />

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        className="Sbutton"
        disabled={isLoading || !stripe || !elements}
        id="submit"
        onClick={handleSubmit}
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay now subcription"
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </div>
  );
}
