import review1 from "../assets/Images/review-1.jpeg";
import ordenadores from "../assets/Images/ordenadores.jpg";
import review2 from "../assets/Images/review-2.jpeg";
import review3 from "../assets/Images/review-3.jpeg";
import Review from "../components/Review";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";
import left from "../assets/Icons/left.svg";
import right from "../assets/Icons/right.svg";

export default function Reviews() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const reviewData = [
    {
      picture: review1,
      name: "Naadja Barnor      ",
      job: "Business Systems Analyst II      ",
      text: "Techxus transformed my life. As a Non-IT graduate, I joined the ITmefy program to switch careers. Today, I stand as a confident IT professional, empowered by the comprehensive training and support. Techxus not only filled the gaps in my knowledge but opened doors to a world of possibilities.",
      link: "https://www.linkedin.com/in/naadja-barnor-902130152/",
    },
    {
      picture: review2,
      name: "Julian Acquah",
      job: "Junior, Virginia Tech",
      text: "ITelevate has been a vital bridge, seamlessly connecting the academic knowledge I gained with the real-world demands of the IT industry. As a student, the program not only expanded my technical skill set but also provided insights into industry practices that textbooks often overlook.",
      link: "https://www.linkedin.com/in/julianacquah/",
    },
    {
      picture: review3,
      name: "Miguel Rivillas",
      job: "Freshman, City Tech",
      text: "LearnView was a game-changer for our family. As a high school student considering IT in college, my parents and I were overwhelmed. LearnView not only clarified the path ahead but also enlightened us about the diverse career opportunities within IT. It's not just a program; it's a roadmap to our future.",
    },

    // Agrega más objetos según sea necesario
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    cssEase: "ease-in-out", // Configuración de la transición suave
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <div className="relative flex flex-col justify-center w-full h-auto pt-20 mt-10">
        <div className="absolute w-full h-full">
          <img src={ordenadores} alt="" className="w-full h-full" />
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        </div>

        <div className="z-10 flex flex-col w-full p-4 text-white lg:p-8 gap-y-6">
          <div className="text-center">
            <h2 className="text-base font-semibold uppercase text-blanco2">
              Testimonals
            </h2>
          </div>
          <div>
            <h3 className="text-3xl font-bold text-center lg:hidden">
              Voices of Impact: Stories of Transformation from Our Beneficiaries
            </h3>{" "}
            <h3 className="hidden text-3xl font-bold text-center lg:block">
              Voices of Impact: Stories of Transformation from <br /> Our
              Beneficiaries
            </h3>
          </div>
          <div className="hidden lg:flex gap-x-4 ">
            {reviewData.map((review, index) => (
              <Review
                key={index}
                name={review.name}
                job={review.job}
                picture={review.picture}
                text={review.text}
                link={review.link}
              />
            ))}
          </div>

          <div className="relative w-full h-full lg:hidden">
            <Slider {...settings} ref={sliderRef} className="">
              {reviewData.map((review, index) => (
                <Review
                  key={index}
                  name={review.name}
                  job={review.job}
                  picture={review.picture}
                  text={review.text}
                  link={review.link}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-20 lg:hidden gap-x-4">
        <button onClick={prevSlide} className="">
          <img src={left} alt="" />
        </button>
        <button onClick={nextSlide} className="">
          <img src={right} alt="" />
        </button>
      </div>
    </>
  );
}
