import profile from "../assets/Icons/profile.png";

export default function Comments({
  name,
  date,
  comment,
  handleApprove,
  handleDelete,
  showButtons,
  approved,
}) {
  return (
    <>
      <div className="flex flex-col w-full p-6 gap-y-6">
        <div className="flex items-center w-full gap-x-4">
          <div className="">
            <img src={profile} className="w-8" alt="" />
          </div>
          <div className="flex flex-col w-full gap-y-6">
            <div className="flex flex-col justify-between gap-y-2">
              <h5 className="text-sm font-semibold">{name}</h5>
              <h6 className="text-xs font-medium">{date}</h6>
            </div>
            <div>
              <h3>{comment}</h3>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            {showButtons && (
              <>
                {!approved || approved === 0 ? (
                  <button
                    className="w-20 h-auto p-2 bg-green-500 rounded"
                    onClick={handleApprove}
                  >
                    Approve
                  </button>
                ) : (
                  <span className="text-green-500">Approved</span>
                )}
                <button
                  className="w-20 h-auto p-2 bg-red-500 rounded"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>
        <div className="w-full  h-0.5 bg-gris8"></div>
      </div>
    </>
  );
}
