import Blog from "./Blog";
import blog1 from "../assets/Images/review-1.jpg";
import blog2 from "../assets/Images/review-2.jpg";
import blog3 from "../assets/Images/review-3.jpg";
import blog4 from "../assets/Images/review-4.jpg";
import profile1 from "../assets/Images/profile-1.jpg";
import profile2 from "../assets/Images/profile-2.jpg";
import profile3 from "../assets/Images/profile-3.jpg";
import profile4 from "../assets/Images/profile-4.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useRef, useState } from "react";
import left from "../assets/Icons/left.svg";
import right from "../assets/Icons/right.svg";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
export default function Blogs() {
  const sliderRef = useRef(null);

  const [blogData, setBlogData] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/posts/list");

        if (response.status === 200) {
          // Aquí puedes manejar la respuesta exitosa
          console.log("Datos recibidos:", response.data);
          setBlogData(response.data.data);
        } else {
          // Manejar otros códigos de estado si es necesario
          console.error("Error en la solicitud:", response.status);
        }
      } catch (error) {
        // Manejar errores de red u otros errores
        console.error("Error en la solicitud:", error.message);
      }
    };

    fetchData(); // Llama a la función de solicitud al montar el componente
  }, []);

  const handleShow = (title, postId) => {
    const encodedTitle = encodeURIComponent(title);
    history.push(`/Blog/${encodedTitle}`, { postId });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    cssEase: "ease-in-out", // Configuración de la transición suave
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <div className="w-full p-4 mt-10 lg:p-10">
        <div className="w-8/12 h-0.5 mx-auto my-0 mt-5 mb-10 bg-gris13"></div>
        <div className="w-full lg:flex lg:items-center lg:justify-center">
          <h2
            className="text-3xl font-semibold text-center uppercase"
            id="blogs"
          >
            our blogs
          </h2>
          {/* <div className="items-center justify-center hidden mt-10 lg:mt-0 lg:flex gap-x-4">
            <button onClick={prevSlide} className="">
              <img src={left} alt="" />
            </button>
            <button onClick={nextSlide} className="">
              <img src={right} alt="" />
            </button>
          </div> */}
        </div>
        <div className="hidden lg:flex gap-x-4 ">
          {blogData.slice(0, 4).map((blog, index) => (
            <Blog
              key={index}
              picture={`https://api.techxus.org/images/${blog.file}`}
              title={blog.title}
              text={
                blog.content.length > 150
                  ? blog.content.slice(0, 150) + "..."
                  : blog.content
              }
              date={blog.date}
              name={"Daniel Acquah"}
              views={blog.views}
              likes={blog.likes}
              comments={blog.comments}
              profile={blog.profile}
              handleShow={() => handleShow(blog.title, blog.id)}
            />
          ))}
        </div>

        <div className="block lg:hidden">
          <Slider {...settings} ref={sliderRef} className="">
            {blogData.slice(0, 4).map((blog, index) => (
              <Blog
                key={index}
                picture={`https://api.techxus.org/images/${blog.file}`}
                title={blog.title}
                text={
                  blog.content.length > 150
                    ? blog.content.slice(0, 150) + "..."
                    : blog.content
                }
                date={blog.date}
                name={blog.name}
                views={blog.views}
                likes={blog.likes}
                comments={blog.comments}
                profile={blog.profile}
              />
            ))}
          </Slider>
        </div>
      </div>

      <div className="flex items-center justify-center mt-10 lg:hidden gap-x-4">
        <button onClick={prevSlide} className="">
          <img src={left} alt="" />
        </button>
        <button onClick={nextSlide} className="">
          <img src={right} alt="" />
        </button>
      </div>

      <div className="items-center justify-center hidden w-full mt-10 lg:flex">
        <Link
          to="/Blogs"
          className="flex items-center justify-center text-white rounded-full w-44 h-14 bg-azul "
        >
          {" "}
          More blog posts
        </Link>
      </div>
    </>
  );
}
