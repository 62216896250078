import Video from "../components/Video";
import bandera from "../assets/Icons/bandera.svg";
import medalla from "../assets/Icons/medalla.svg";
import ojo from "../assets/Icons/ojo.svg";
export default function About() {
  return (
    <>
      <div
        id="about"
        name="about"
        className="flex flex-col w-full p-8 mt-10 lg:items-center lg:flex-row gap-y-6"
      >
        <div className="w-full lg:w-2/5">
          <Video />
        </div>
        <div className="flex flex-col w-full h-auto lg:w-2/5 lg:mx-auto lg:my-0 gap-y-8">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-lg font-semibold text-azul font-montserrat">
              ABOUT US
            </h2>

            <h3 className="text-sm font-normal text-negro">
              At Techxus, where we're committed to breaking down barriers and
              empowering minorities through technology training and education.
              At Techxus, we're proud to offer{" "}
              <b className="text-rojo">100% FREE</b> resources, ensuring that
              accessibility is never a hindrance to your learning journey.
            </h3>
          </div>

          <div className="flex flex-col gap-y-6">
            <div className="flex flex-col w-full gap-y-4">
              <div className="flex flex-col items-start lg:items-center lg:flex-row lg:gap-x-4 gap-y-4">
                <img src={bandera} alt="" />
                <h2 className="text-xl font-bold text-black">Mission</h2>
              </div>

              <div>
                <h3 className="text-sm font-normal text-negro">
                  Our mission is clear and compelling: to empower minorities to
                  become self-sufficient trailblazers in the world of
                  technology. We believe in fostering innovation as a pathway to
                  self-reliance, and we are dedicated to providing the resources
                  and support necessary for minorities to excel and thrive.
                </h3>
              </div>
            </div>

            <div className="flex flex-col w-full gap-y-4">
              <div className="flex flex-col items-start lg:items-center lg:flex-row lg:gap-x-4 gap-y-4">
                <img src={ojo} alt="" />
                <h2 className="text-xl font-bold text-black">Vision</h2>
              </div>

              <div>
                <h3 className="text-sm font-normal text-negro">
                  Techxus envisions an empowered future for minorities through
                  technology education. We strive for a world where every
                  individual, regardless of background, possesses the skills and
                  knowledge needed to thrive in the digital landscape.
                </h3>
              </div>
            </div>

            <div className="flex flex-col w-full gap-y-4">
              <div className="flex flex-col items-start lg:items-center lg:flex-row lg:gap-x-4 gap-y-4">
                <img src={medalla} alt="" />
                <h2 className="text-xl font-bold text-black">
                  501c Certification
                </h2>
              </div>

              <div>
                <h3 className="text-sm font-normal text-negro">
                  Techxus is proud to be a 501c3 Non-Profit organization,
                  headquartered in the vibrant city of New York, United States.
                  Our commitment is reflected in our status, ensuring that every
                  effort we make is dedicated to the betterment of the
                  community. As a 501c3 organization, we prioritize
                  accessibility, offering <b className="text-rojo">100% FREE</b>{" "}
                  technology training and education resources.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-8/12 h-0.5 mx-auto my-0 mt-10 mb-20 bg-gris13"></div>
    </>
  );
}
