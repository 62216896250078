import React, { useRef, useState, useEffect } from "react";
import video from "../assets/Video/aboutus-video.mp4";
import picture from "../assets/Images/about-1.jpg";

function VideoPrograms({ isOpen, onClose }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    videoElement.addEventListener("play", handlePlay);

    // Limpiar los event listeners al desmontar
    return () => {
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isOpen) {
      // Iniciar la reproducción del video cuando se abre el componente
      videoElement.play().catch((error) => {
        console.error("Error trying to play the video:", error);
      });
    } else {
      // Pausar el video cuando se cierra el componente
      videoElement.pause();
    }
  }, [isOpen]);

  const videoContainerStyle = {
    display: isOpen ? "flex" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const videoStyle = {
    maxWidth: "85%",
    maxHeight: "85%",
  };

  return (
    <div style={videoContainerStyle} onClick={onClose}>
      <video
        ref={videoRef}
        poster={picture}
        controls={true}
        className="video rounded-xl"
        style={videoStyle}
      >
        <source src={video} type="video/mp4" />
        Tu navegador no admite la reproducción de video.
      </video>
    </div>
  );
}

export default VideoPrograms;
